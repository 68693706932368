import React, { useEffect, useState } from "react";
import useReportNarrativeStore from "../../store/report_narrative.store";
import { SkeletonBar } from "../SkeletonBar";
import { IconButton, Button } from "@material-tailwind/react";
import clsx from "clsx";
import { WhyDescDialog } from "./edit/WhyDescDialog";
import { BiColorFill, BiSolidPencil } from "react-icons/bi";

import { useParams } from "react-router-dom";
import { ColorPickerDialog } from "./edit/ColorPickerDialog.jsx";
import useReportClientStore from "../../store/report_client.store.js";

const RecommendSection = ({}) => {
  const narrativeData = useReportNarrativeStore((state) => state.data);
  const isLoading = useReportNarrativeStore((state) => state.isLoading);
  const fetchNarrative = useReportNarrativeStore(
    (state) => state.fetchNarrative
  );
  const client = useReportClientStore((state) => state.data);

  const patientId = useParams("id").id;

  const [recDesc, setRecDesc] = useState(narrativeData?.["RECVIS"]?.desc);
  const [recList, setRecList] = useState(narrativeData?.["RECVIS"]?.list);

  const [isEditDesc, setIsEditDesc] = useState(false);
  const [isEditList, setIsEditList] = useState(false);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [color, setColor] = useState('#d1d7da');

  useEffect(() => {
    setRecDesc(narrativeData?.["RECVIS"]?.desc);
    setRecList(narrativeData?.["RECVIS"]?.list);
  }, [narrativeData]);

  const onEditDesc = () => {    
    setIsEditDesc(!isEditDesc);
  };
  const onEditList = () => {
    setIsEditList(!isEditList);
  };

  const onUpdateCallback = async () => {
    await fetchNarrative(patientId);
  };
  
  const onEditColor = () => {
    setIsEditingColor(editing => !editing);
  }

  return (
    <section className="mb-[30px] ">
      <div className="container ">
        <div className="tile flex flex-col gap-[30px] theme-dark group/parent relative" style={{backgroundColor: narrativeData?.["RECVIS"]?.color ? narrativeData?.["RECVIS"]?.color : color}}>
          <div className="absolute top-2 right-2  hidden group-hover/parent:block">            
            <Button
              variant="gradient"
              color="green"
              size="sm"
              className=" bg-cyan-500 mr-2"
              onClick={onEditList}
            >
              <span>+ Add Item</span>
            </Button>
            <IconButton
              size="sm"
              color="black"
              data-ripple-light="true"
              data-dialog-target="dialog"
              className={clsx(
                " bg-teal-500 w-3 h-3 px-3 ",
                "text-white text-md"
              )}
              onClick={() => onEditColor(0)}
            >
              <BiColorFill />
            </IconButton>

            <WhyDescDialog
              dlgTitle={'"Recommendations"'}
              open={isEditList}
              data={narrativeData?.["RECVIS"]}
              initValue={recList}
              isList={true}
              isSimpleList={false}
              listItemFields={["title", "description"]}
              listItemInputTypes={["input", "textarea"]}
              onToggle={onEditList}
              callBack={onUpdateCallback}
            />
            <ColorPickerDialog
              open={isEditingColor}
              onToggle={onEditColor}
              defaultColor={narrativeData?.["RECVIS"]?.color ? narrativeData?.["RECVIS"]?.color : color}
              callBack={onUpdateCallback}
              nid={narrativeData?.["RECVIS"]?.narrativeTypeId}
              isPracticeAdmin={client.isPracticeAdmin}
            />
          </div>
          <div className="flex flex-col md:flex-row items-center gap-[30px] ">
            <div className="flex flex-col basis-1/2 max-w-text grow gap-[30px] ">
              <p className="eyebrow">Recommendations</p>
              <h2 className="text-serif-h2">
                If you do nothing else, do these!
              </h2>
              <SkeletonBar rows={3} isLoading={isLoading} />
              <div className="group relative">
                {!isLoading && recDesc}

                <div className="absolute top-0 right-0  hidden group-hover:block">
                  <IconButton
                    size="sm"
                    color="black"
                    data-ripple-light="true"
                    data-dialog-target="dialog"
                    className={clsx(
                      " bg-teal-500 w-3 h-3 px-3 ",
                      "text-white text-md"
                    )}
                    onClick={onEditDesc}
                  >
                    <BiSolidPencil />
                  </IconButton>

                  <WhyDescDialog
                    dlgTitle={'"Recommendations"'}
                    open={isEditDesc}
                    data={narrativeData?.["RECVIS"]}
                    initValue={recDesc}
                    isList={false}
                    // isSimpleList={false}
                    // listItemFields={["title", "description"]}
                    // listItemInputTypes={["input", "textarea"]}
                    onToggle={onEditDesc}
                    callBack={onUpdateCallback}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap gap-[30px]">
            {!isLoading &&
              recList?.map((item, index) => {
                return (
                  <div key={"rv-" + index} className="tile-card">
                    <div className="mb-2">
                      <p className="font-bold">{item.title}</p>
                    </div>

                    <div>{item.description}</div>
                  </div>
                );
              })}
          </div>

          <div className="grid grid-cols-12 gap-[30px] items-center">
            <div className="col-span-12 md:col-span-8">
              <p>
                <strong>Full recommendations</strong> (More detailed
                recommendations if time, energy, and money allow)
              </p>
            </div>

            <div className="col-span-12 md:col-span-4 flex flex-row md:flex-row md:justify-end gap-[30px]">

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecommendSection;
