import React, { useEffect, useState } from "react";
import useReportNarrativeStore from "../../store/report_narrative.store";
import { SkeletonBar } from "../SkeletonBar";
import {
  IconButton,
} from "@material-tailwind/react";
import { BiSolidPencil, BiColorFill } from "react-icons/bi";

import clsx from "clsx";
import { WhyDescDialog } from "./edit/WhyDescDialog";
import { useParams } from "react-router-dom";
import { ImageDialog } from "./edit/ImageDialog.jsx";
import { ColorPickerDialog } from "./edit/ColorPickerDialog.jsx";
import { UpdateNarrativeImage } from "../../apis/Practice.js";
import useReportClientStore from "../../store/report_client.store.js";

const DescriptionSections = ({}) => {
  
  const narrativeData = useReportNarrativeStore((state) => state.data);
  const isLoading = useReportNarrativeStore((state) => state.isLoading);
  const fetchNarrative = useReportNarrativeStore((state) => state.fetchNarrative);
  const client = useReportClientStore((state) => state.data);
  const patientId = useParams("id").id;

  const [whyDesc, setWhyDesc] = useState(narrativeData["WHY"]?.desc);
  const [bulletList, setBulletList] = useState(
    narrativeData?.["WHYBULLET"]?.list
  );
  const [goingWellHead, setGoingWellHead] = useState(
    narrativeData?.["GOINGWELLHEAD"]?.desc
  );
  const [goingWellDesc, setGoingWellDesc] = useState(
    narrativeData["GOINGWELL"]?.desc
  );
  const [postDesc, setPostDesc] = useState(
    narrativeData?.["POSTATEMENT"]?.desc
  );
  const [chDesc, setChDesc] = useState(narrativeData?.["CHALLENGEVIS"]?.desc);
  const [chList, setChList] = useState(narrativeData["CHALLENGEVIS"]?.list);

  const [isEditingWhyDesc, setIsEditingWhyDesc] = useState(false);

  const [isEditingBulletList, setIsEditingBulletList] = useState(false);

  const [isEditingGoingWell, setIsEditingGoingWell] = useState(false);
  const [isEditingGoingWellDesc, setIsEditingGoingWellDesc] = useState(false);

  const [isEditingPostDesc, setIsEditingPostDesc] = useState(false);

  const [isEditingChDesc, setIsEditingChDesc] = useState(false);
  const [isEditingChList, setIsEditingChList] = useState(false);
  useEffect(() => {
    setWhyDesc(narrativeData?.["WHY"]?.desc);
    setBulletList(narrativeData?.["WHYBULLET"]?.list);
    setGoingWellHead(narrativeData?.["GOINGWELLHEAD"]?.desc);

    setGoingWellDesc(narrativeData["GOINGWELL"]?.desc);
    setPostDesc(narrativeData?.["POSTATEMENT"]?.desc);
    setChDesc(narrativeData?.["CHALLENGEVIS"]?.desc);
    setChList(narrativeData?.["CHALLENGEVIS"]?.list);
    setImage(narrativeData["GOINGWELLHEAD"]?.imageUrl ?? '/assets/images/puzzle.jpg');
  }, [narrativeData]);

  const onUpdateCallback = async(img = undefined)=>{
    await fetchNarrative(patientId);
    if(typeof(img) == String) {
      setImage(img);
    }
  }

  const onEditWhyDesc = () => {
    setIsEditingWhyDesc(!isEditingWhyDesc);
  };

  const onEditWhyBullet = () => {
    setIsEditingBulletList(!isEditingBulletList);
  };

  const onEditGoingWellHead = () => {
    setIsEditingGoingWell(!isEditingGoingWell);
  };
  const onEditGoingWellDesc = () => {
    setIsEditingGoingWellDesc(!isEditingGoingWellDesc);
  };

  const onEditPostDesc = () => {
    setIsEditingPostDesc(!isEditingPostDesc);
  };

  const onEditColor = (index)=>{
    setIsEditingColor(index);
  }
  const onEditChDesc = ()=>{
    setIsEditingChDesc(!isEditingChDesc);
  }
  const onEditChList = ()=>{
    setIsEditingChList(!isEditingChList);
  }

  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isEditingColor, setIsEditingColor] = useState(-1);
  const color = ['#f2ede1', '#f2ede1', '#24344b'];
  const onEditImage = () => {
    setIsEditingImage(isEditing => !isEditing);
  }

  const [image, setImage] = useState(narrativeData?.["GOINGWELLHEAD"]?.imageUrl ? narrativeData?.["GOINGWELLHEAD"]?.imageUrl : '/assets/images/puzzle.jpg');

  return (
    <>
      <section className="mb-20">
        <div className="container hover:outline-1 rounded-md transition-all !ease-linear">
          <div className="grid-layout justify-between">
            <div className="col-span-12 lg:col-span-6">
              <div className="max-w-text relative group">
                <div className="absolute top-0 right-0  hidden group-hover:block">
                  <IconButton
                    size="sm"
                    color="black"
                    data-ripple-light="true"
                    data-dialog-target="dialog"
                    className={clsx(
                      " bg-teal-500 w-3 h-3 px-3 ",
                      "text-white text-md"
                    )}
                    onClick={onEditWhyDesc}
                  >
                    <BiSolidPencil />
                  </IconButton>

                  <WhyDescDialog
                    open={isEditingWhyDesc}
                    dlgTitle={'"Why are we here?"'}
                    data={narrativeData?.["WHY"]}
                    initValue={whyDesc}
                    onToggle={onEditWhyDesc}
                    callBack={onUpdateCallback}
                  />
                </div>

                <h2 className="text-serif-h2 mb-3">Why are we here?</h2>
                <SkeletonBar rows={3} isLoading={isLoading} />
                {!isLoading && <p className="whitespace-pre-wrap">{whyDesc}</p>}
              </div>
            </div>
            <ul className="flex flex-col justify-center col-span-12 lg:col-span-5 lg:col-start-8 relative group">
              <div className="absolute top-0 right-0  hidden group-hover:block">
                <IconButton
                  size="sm"
                  color="black"
                  data-ripple-light="true"
                  data-dialog-target="dialog"
                  className={clsx(
                    " bg-teal-500 w-3 h-3 px-3 ",
                    "text-white text-md"
                  )}
                  onClick={onEditWhyBullet}
                >
                  <BiSolidPencil />
                </IconButton>

                <WhyDescDialog
                  dlgTitle={'"Why are we here?"'}
                  open={isEditingBulletList}
                  data={narrativeData?.["WHYBULLET"]}
                  initValue={bulletList}
                  isList={true}
                  onToggle={onEditWhyBullet}
                  callBack={onUpdateCallback}
                />
              </div>

              {isLoading && <SkeletonBar rows={3} isLoading={isLoading} />}
              {!isLoading &&
                bulletList?.map((bullet, index) => {
                  return (
                    <li
                      key={"kln-" + index}
                      className="checklist-item mb-[6px] flex flex-row "
                    >
                      <p className="font-semibold ml-2">{bullet}</p>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-[30px] ">
        <div className="container">
          <div className="tile flex flex-col gap-[30px] theme-dark relative group/parent" style={{backgroundColor: narrativeData?.["GOINGWELL"]?.color ? narrativeData?.["GOINGWELL"]?.color : color[0]}}>
            <div className="absolute top-2 right-2  hidden group-hover/parent:block">
              <IconButton
                size="sm"
                color="black"
                data-ripple-light="true"
                data-dialog-target="dialog"
                className={clsx(
                  " bg-teal-500 w-3 h-3 px-3 ",
                  "text-white text-md"
                )}
                onClick={() => onEditColor(0)}
              >
                <BiColorFill />
              </IconButton>
              <ColorPickerDialog
                open={isEditingColor == 0}
                onToggle={() => isEditingColor > -1 ? onEditColor(-1) : onEditColor(0)}
                defaultColor={narrativeData?.["GOINGWELL"]?.color ? narrativeData?.["GOINGWELL"]?.color : color[0]}
                callBack={onUpdateCallback}
                nid={narrativeData?.["GOINGWELL"]?.narrativeTypeId}
                isPracticeAdmin={client.isPracticeAdmin}
              />
            </div>
            <div className="flex flex-col md:flex-row items-center gap-[30px]">
              <div className="flex flex-col basis-1/2 max-w-text grow gap-[30px] ">
                <p className="eyebrow">What’s going well?</p>
                <div className="text-serif-h2 group/child relative">
                  <SkeletonBar rows={1} isLoading={isLoading} />
                  {!isLoading && goingWellHead}
                  <div className="absolute top-0 right-0  hidden group-hover/child:block">
                    <IconButton
                      size="sm"
                      color="black"
                      data-ripple-light="true"
                      data-dialog-target="dialog"
                      className={clsx(
                        " bg-teal-500 w-3 h-3 px-3 ",
                        "text-white text-md"
                      )}
                      onClick={onEditGoingWellHead}
                    >
                      <BiSolidPencil />
                    </IconButton>

                    <WhyDescDialog
                      dlgTitle={'"What\'s going well?"'}
                      open={isEditingGoingWell}
                      data={narrativeData?.["GOINGWELLHEAD"]}
                      initValue={goingWellHead}
                      isList={false}
                      onToggle={onEditGoingWellHead}
                      callBack={onUpdateCallback}
                    />
                  </div>
                </div>
                <div className="relative group/child whitespace-pre-wrap">
                  <SkeletonBar
                    rows={3}
                    isLoading={isLoading}
                    className={"!h-3 opacity-55"}
                  />
                  {!isLoading && goingWellDesc}
                  <div className="absolute top-0 right-0  hidden group-hover/child:block">
                    <IconButton
                      size="sm"
                      color="black"
                      data-ripple-light="true"
                      data-dialog-target="dialog"
                      className={clsx(
                        " bg-teal-500 w-3 h-3 px-3 ",
                        "text-white text-md"
                      )}
                      onClick={onEditGoingWellDesc}
                    >
                      <BiSolidPencil />
                    </IconButton>

                    <WhyDescDialog
                      dlgTitle={'"What\'s going well?"'}
                      open={isEditingGoingWellDesc}
                      data={narrativeData?.["GOINGWELL"]}
                      initValue={goingWellDesc}
                      isList={false}
                      onToggle={onEditGoingWellDesc}
                      callBack={onUpdateCallback}
                    />
                  </div>
                </div>
              </div>
              <div className="basis-1/2 grow group/child relative">
                <img
                  className="rounded-[10px]"
                  src={image}
                  alt="Placeholder Image"
                />
                <div className="absolute top-2 right-2  hidden group-hover/child:block">
                  <IconButton
                    size="sm"
                    color="black"
                    data-ripple-light="true"
                    data-dialog-target="dialog"
                    className={clsx(
                      " bg-teal-500 w-3 h-3 px-3 ",
                      "text-white text-md"
                    )}
                    onClick={onEditImage}
                  >
                    <BiSolidPencil />
                  </IconButton>

                  <ImageDialog
                    open={isEditingImage}
                    defaultImage={image}
                    onToggle={onEditImage}
                    callBack={onUpdateCallback}
                    cropSize={{width: 400, height: 218}}
                    updateImage={(i, j, k) => UpdateNarrativeImage(narrativeData?.["GOINGWELLHEAD"]?.narrativeTypeId, i, j, k)}
                    isPracticeAdmin={client.isPracticeAdmin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[30px]">
        <div className="container">
          <div className="tile theme-dark relative group" style={{backgroundColor: narrativeData?.["POSTATEMENT"]?.color ? narrativeData?.["POSTATEMENT"]?.color : color[1]}}>
            <div className="absolute top-2 right-2  hidden group-hover:block">
              <IconButton
                size="sm"
                color="black"
                data-ripple-light="true"
                data-dialog-target="dialog"
                className={clsx(
                  " bg-teal-500 w-3 h-3 px-3 ",
                  "text-white text-md"
                )}
                onClick={() => onEditColor(1)}
              >
                <BiColorFill />
              </IconButton>
              <ColorPickerDialog
                open={isEditingColor == 1}
                onToggle={() => isEditingColor > -1 ? onEditColor(-1) : onEditColor(1)}
                defaultColor={narrativeData?.["POSTATEMENT"]?.color ? narrativeData?.["POSTATEMENT"]?.color : color[1]}
                callBack={onUpdateCallback}
                nid={narrativeData?.["POSTATEMENT"]?.narrativeTypeId}
                isPracticeAdmin={client.isPracticeAdmin}
              />
            </div>
            <div className="font-serif text-serif-h1 relative group">
              <SkeletonBar
                rows={3}
                isLoading={isLoading}
                className={"opacity-55"}
              />
              {!isLoading && postDesc}
              <div className="absolute top-[-15px] right-0  hidden group-hover:block">
                <IconButton
                  size="sm"
                  color="black"
                  data-ripple-light="true"
                  data-dialog-target="dialog"
                  className={clsx(
                    " bg-teal-500 w-3 h-3 px-3 ",
                    "text-white text-md"
                  )}
                  onClick={onEditPostDesc}
                >
                  <BiSolidPencil />
                </IconButton>

                <WhyDescDialog
                  dlgTitle={'"Post Statement"'}
                  open={isEditingPostDesc}
                  data={narrativeData?.["POSTATEMENT"]}
                  initValue={postDesc}
                  isList={false}
                  onToggle={onEditPostDesc}
                  callBack={onUpdateCallback}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[30px]">
        <div className="container">
          <div className="tile flex flex-col gap-[30px] theme-light group relative" style={{backgroundColor: narrativeData?.["CHALLENGEVIS"]?.color ? narrativeData?.["CHALLENGEVIS"]?.color : color[2]}}>
            <div className="absolute top-2 right-2  hidden group-hover:block">
              <IconButton
                size="sm"
                color="black"
                data-ripple-light="true"
                data-dialog-target="dialog"
                className={clsx(
                  " bg-teal-500 w-3 h-3 px-3 ",
                  "text-white text-md"
                )}
                onClick={() => onEditColor(2)}
              >
                <BiColorFill />
              </IconButton>
              <ColorPickerDialog
                open={isEditingColor == 2}
                onToggle={() => isEditingColor > -1 ? onEditColor(-1) : onEditColor(2)}
                defaultColor={narrativeData?.["CHALLENGEVIS"]?.color ? narrativeData?.["CHALLENGEVIS"]?.color : color[2]}
                callBack={onUpdateCallback}
                nid={narrativeData?.["CHALLENGEVIS"]?.narrativeTypeId}
                isPracticeAdmin={client.isPracticeAdmin}
              />
            </div>
            <div className="flex flex-col md:flex-row items-center gap-[30px] group/parent relative ">
              <div className="flex flex-col basis-1/2 max-w-text grow gap-[30px] ">
                <p className="eyebrow">What’s challenging?</p>
                <h2 className="text-serif-h2">
                  <SkeletonBar rows={1} isLoading={isLoading} />
                  {!isLoading && chDesc}
                </h2>
              </div>
              <div className="absolute top-0 right-0  hidden group-hover/parent:block">
                <IconButton
                  size="sm"
                  color="black"
                  data-ripple-light="true"
                  data-dialog-target="dialog"
                  className={clsx(
                    " bg-teal-500 w-3 h-3 px-3 ",
                    "text-white text-md"
                  )}
                  onClick={onEditChDesc}
                >
                  <BiSolidPencil />
                </IconButton>

                <WhyDescDialog
                  dlgTitle={'"Challenge Points"'}
                  open={isEditingChDesc}
                  data={narrativeData?.["CHALLENGEVIS"]}
                  initValue={chDesc}
                  isList={false}
                  onToggle={onEditChDesc}
                  callBack={onUpdateCallback}
                />
              </div>
            </div>

            <div className="flex flex-wrap gap-[30px] group/child relative">
              <SkeletonBar
                rows={1}
                isLoading={isLoading}
                className={"opacity-55 !h-3"}
              />

              <div className="absolute top-0 right-0  hidden group-hover/child:block">
                <IconButton
                  size="sm"
                  color="black"
                  data-ripple-light="true"
                  data-dialog-target="dialog"
                  className={clsx(
                    " bg-teal-500 w-3 h-3 px-3 ",
                    "text-white text-md"
                  )}
                  onClick={onEditChList}
                >
                  <BiSolidPencil />
                </IconButton>
                <WhyDescDialog
                  dlgTitle={'"Challenge Description"'}
                  open={isEditingChList}
                  data={narrativeData?.["CHALLENGEVIS"]}
                  initValue={chList}
                  isList={true}
                  isSimpleList={false}
                  listItemFields={["title", "description"]}
                  listItemInputTypes={["input", "textarea"]}
                  onToggle={onEditChList}
                  callBack={onUpdateCallback}
                />
              </div>

              {!isLoading &&
                chList?.map((challenge, index) => {
                  return (
                    <div key={"chikk-" + index} className="tile-card">
                      <div className="mb-2">
                        <p className="font-bold">{challenge?.title}</p>
                      </div>
                      <div>{challenge?.description}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DescriptionSections;
