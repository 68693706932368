import React, {useState} from "react";
import { CalcDataPoint } from "../utils";
import { Tooltip } from "@material-tailwind/react";
import ToolTipContent from "./TooltipContent";

const CompositeTrack = ({ section, min, max, image = false }) => {

    const [openToolTip, setOpenToolTip] = useState(false);
  
    const ConfidenceEl = ({ section, min, max }) => {
      if (section.composite.confidence) {
        let conf = null;
        let confMin = null;
        let confMax = null;
  
        if (Array.isArray(section.composite.confidence)) {
          [confMin, confMax] = section.composite.confidence;
        } else {
          conf = section.composite.confidence;
        }
  
        const { left, width } = CalcDataPoint(min, max, confMin, confMax, conf);
  
        return (
          <div
            className={`dot-plot-score dot-plot-thin `}
            style={{ left, width,  backgroundColor:section.bgColor }}
          ></div>
        );
      }
      return null;
    };
  
    
  
    const ValueEl = ({ section, min, max, openToolTip, image = false }) => {
      if (section.composite.value) {
        let val = null;
        let valMin = null;
        let valMax = null;
  
        if (Array.isArray(section.composite.value)) {
          [valMin, valMax] = section.composite.value;
        } else {
          val = section.composite.value;
        }
  
        const { left, width, leftDis } = CalcDataPoint(min, max, valMin, valMax, val);
  
        if (section.composite.tooltip && section.composite.tooltip.length) {
          return (
            <Tooltip
              open={openToolTip}
              className={
                "bg-white text-black shadow-md shadow-blue-gray-400 "
              }
              content={ <ToolTipContent tooltip={section.composite.tooltip}/> }
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <div>
              <div
                className={`dot-plot-score dot-plot-thick`}
                style={{ left, width, backgroundColor:section.bgColor }}
                data-tippy-content={section.composite.tooltip}
              ></div>
              <div style={{ left:`${leftDis}`, top:image ? '-3px':'2px', position:'absolute' }}>{section.composite.valueDisplay}</div>
              </div>
            </Tooltip>
          );
        } else {
          return (
            <div
              className={`dot-plot-score dot-plot-thick `}
              style={{ left, width, backgroundColor:section.bgColor }}
            ><div style={{ left:'10px', top:image ? '-3px':'2px', position:'absolute' }}>{section.composite.valueDisplay}</div></div>
          );
        }
      } else {
        return null;
      }
    };
  
    const showToolTip = () => {
      setOpenToolTip(true);
    };
  
    const hideToolTip = () => {
      setOpenToolTip(false);
    };
  
    if (section.composite.tooltip) {
      return (
        <div
          className="dot-plot-track"
          onMouseEnter={showToolTip}
          onMouseLeave={hideToolTip}
        >
          <ConfidenceEl section={section} min={min} max={max} />
          <ValueEl
            section={section}
            min={min}
            max={max}
            openToolTip={openToolTip} 
            image={image}
          />
        </div>
      );
    } else {
      return (
        <div className="dot-plot-track">
          <ConfidenceEl section={section} min={min} max={max} />
          <ValueEl section={section} min={min} max={max} image={image} />
        </div>
      );
    }
  };


  export default CompositeTrack;