import { useEffect, useState } from "react";
import RenderGraphScaleItems from "./RenderGraphScaleItems";
import RenderGraphScaleLabels from "./RenderGraphScaleLabels";

export default function InjectDotPlotScale({ data }) {
  const [isItemsRendered, setIsItemsRendered] = useState(false);
  useEffect(()=>{

    const checkDivExists = () => {
      const element = document.querySelector('.graph-scale-item');
      if (element) {
        setIsItemsRendered(true);
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(checkDivExists, 100);

    setTimeout(() => {
      setIsItemsRendered(true);
      clearInterval(intervalId);
    }, 3000);
    
 },[])

  if (!data) return null;
  const { min, max, step, noNumber, dotSections, labels, atRisk } = data.scale;

  return (
    <div className="dot-plot-scale">
      <div className="grid grid-cols-12 absolute top-0 left-0 w-full h-full z-10">
        <div className="graph-scale col-span-9 col-start-4 flex-col">
          <div className="graph-scale-labels w-full flex flex-row grow-0 shrink justify-between relative px-5 pt-5">
            {isItemsRendered &&
            <RenderGraphScaleLabels
              min={min}
              max={max}
              step={step}
              noNumber={noNumber}
              dotSections={dotSections}
              labels={labels}
              reversed={false}
            />}
          </div>
          <div className="graph-scale-items flex flex-row shrink-0 grow justify-between px-5">
            <RenderGraphScaleItems
              min={min}
              max={max}
              step={step}
              atRisk={atRisk}
              noNumber={noNumber}
              dotSections={dotSections}
              labels={labels}
              reversed={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
