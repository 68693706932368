import React, { useState, useEffect, useRef, useCallback } from "react";
import parser from "html-react-parser";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Lock, Update, ReProcess, PollProgress, Get, GetHistory, Feedback } from "../../apis/Narratives";
import { Spinner } from "@material-tailwind/react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import DiffMatchPatch from 'diff-match-patch';
import '../FeedbackSystem.css';

export function PlaceHolder(props) {
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [spinnerText, setSpinnerText] = useState("");
  const [lockFlag, setLockFalg] = useState(props.data.lock ?? false);
  const [focused, setFocused] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [narrativeText, setNarrativeText] = useState(props.data.narrativeText);
  const [customSelect, setCustomSelect] = useState(false);
  const [showHistoryDetail, setShowHistoryDetail] = useState(false);
  const [historyDetail, setHistoryDetail] = useState(false);
  const [historyNavIndex, setHistoryNavIndex] = useState(0);
  const [showDiffChecked, setShowDiffChecked] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackOption, setFeedbackOption] = useState('Not accurate');
  const [comments, setComments] = useState('');
  const [rating, setRating] = useState(props.data.rate ?? 0);
  const [pkid, setPkid] = useState('');

  const divRef = useRef(null);
  const rteObj = useRef(null);
  const divHisRef = useRef(null);
  const divHisTextRef = useRef(null);

  let customToolBar = [
    {
      template:
        `<button type="button" class="e-tbar-btn e-btn" tabindex="-1"><i style="min-width: 24px;line-height: 25px;" class="fas fa-lock-open"></i></button>`,
      tooltipText: 'Lock',
      text: 'Lock',
      id: props.data.pkid
    },
    {
      template:
        '<button type="button" class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar_help"><div class="e-tbar-btn-text" style="font-weight: 800;line-height: 25px;">Rewrite</div></button>',
      click: () => {
        setReadOnlyMode(true);
        setopenDialog(true);
      },
      tooltipText: 'Rewrite',
      text: 'ReProcess',
      id: props.data.pkid
    },
    {
      template:
        '<select class="my-custom-select"><option>Add history</option><option>Add history2</option><option>Add history</option></select>',
      click: () => { },
      tooltipText: 'Narrative History',
      id: props.data.pkid
    },
    {
      template:
        `<button type="button" class="e-tbar-btn e-btn" tabindex="-1"><i class="fas fa-save" style="min-width: 24px;line-height: 25px;"></i></button>`,
      tooltipText: 'Save',
      text: 'Save',
      click: () => { update() },
      id: props.data.pkid
    },
    {
      template: `
        <div class="e-tbar-rating" title="Rate">
          <div class="star-rating" style="display: flex; align-items: center;">
              <p class="star stari" tabindex="0" id=${props.data.pkid}><i class="fa-regular fa-star"></i></p>
              <p class="star stari" tabindex="0" id=${props.data.pkid}><i class="fa-regular fa-star"></i></p>
              <p class="star stari" tabindex="0" id=${props.data.pkid}><i class="fa-regular fa-star"></i></p>
              <p class="star stari" tabindex="0" id=${props.data.pkid}><i class="fa-regular fa-star"></i></p>
              <p class="star" tabindex="0" id=${props.data.pkid}><i class="fa-regular fa-star"></i></p>
          </div>
        </div>
      `,
      tooltipText: "Rate",
      id: props.data.pkid,
      text: 'Rate',
      click: () => {}
    }
  ];

  const rate = (stars, pkid) => {
    setRating(stars);
    setPkid(pkid);
    if (stars < 5) {
      setShowFeedbackModal(true);
    } else {
      Feedback(pkid, '', '', 5)
      .then(() => {
        setShowSpinner(false);
        setSpinnerText("");    
        let stars = document.querySelectorAll('.star');
        stars.forEach((star, index) => {
          if (star.id != pkid) return;
          star.classList.remove('star-focused');
          star.classList.add('star-focused');
        })
      });
    }
  }

  customToolBar = customToolBar.map(a => {
    if (props.data.history?.length > 0 && a.tooltipText == 'Narrative History') {
      let option = "<option selected disabled>Narrative History</option>";
      props.data.history.forEach(ele => {
        option += `<option value=${ele.pkid}>${ele.changedOnDisplay}</option>`;
      });
      a.template = `<select class="e-rte-dropdown-btn my-custom-select-${props.data.pkid}">${option}</select>`
    }
    return a;
  });

  customToolBar = customToolBar.filter(a => props.data.history?.length > 0 || a.tooltipText != 'Narrative History');

  var defaultItems = [
    ...customToolBar,'-'
  ];

  const toolbarSettings = {
    items: defaultItems,
    type: "MultiRow",
    enableFloating: true
  };

  const inlineMode = {
    enable: false,
    onSelection: false,
  };

  useEffect(() => {
    if (!rteObj) return;
    setReadOnlyMode(lockFlag);
    let stars = document.querySelectorAll('.star');
    function handleStarClick(index, pkid) {
      rate((index % 5) + 1, pkid);
    }
  
    if (stars) {
      stars.forEach((star, index) => {
        if (star._handleStarClick) {
          star.removeEventListener('click', star._handleStarClick);
        }
        const clickHandler = () => handleStarClick(index, star.id);
        star._handleStarClick = clickHandler;
        star.addEventListener('click', clickHandler);
        if (star.id == props.data.pkid) {
          star.classList.remove("star-focused");
          if (index % 5 < rating) {
            star.classList.add("star-focused");
          }
        }
      });
    }

    return () => {
      stars.forEach((star) => {
        if (star._handleStarClick) {
          star.removeEventListener('click', star._handleStarClick);
        }
      });
    };
  }, [rteObj, lockFlag, rating]);
  

  const handleToolbarClick = (args) => {
    if (args.item.text === 'Lock') {
      lock()
    }
  };

  const handleDropdownChange = (event) => {
    getHistory(event.target.value);
  };

  const handleRteClick = (event) => {
    if (!customSelect) {
      setTimeout(() => {
        setCustomSelect(true);
        let select = document.querySelector(`.my-custom-select-${props.data.pkid}`);
        if (select) {
          select.addEventListener('change', handleDropdownChange);
        }
      }, 2000);
    }
    if (lockFlag) {
      const element = divRef.current; // Access the DOM element via ref

      if (!element) return; // Ensure the element exists

      const rect = element.getBoundingClientRect();
      // Calculate relative position
      const offsetX = event.clientX - rect.left; // Mouse x relative to the element
      const offsetY = event.clientY - rect.top; // Mouse y relative to the element

      // Position the popup slightly offset from the click position
      setPopupPosition({
        top: offsetY + 10,
        left: offsetX + 10
      });
      setShowPopup(true);
    }
  };

  const handleRteBlur = (event) => {
    const relatedElement = event.relatedTarget; 
    let canUpdate = !relatedElement?.classList?.contains('e-tbar-btn') && 
                    !relatedElement?.classList?.contains('e-rte-dropdown-btn') &&
                    !(relatedElement?.getAttribute('role') == "menu");
    canUpdate = canUpdate && !relatedElement?.classList?.contains('e-tbar-rating') && 
                !relatedElement?.classList?.contains('star') &&
                !(relatedElement?.getAttribute('role') == "menu");

    if (!lockFlag && focused && canUpdate) update();
    setFocused(false);

    if (!(relatedElement && relatedElement.classList && relatedElement.classList.contains('lock-btn')))
      setShowPopup(false);

    if(canUpdate || relatedElement === null)
      hideToolBar();
  };

  const handleRteFocus = () => {
    if(!lockFlag)
      document.querySelector(`#${props.data.narrativeTypeCode} .e-rte-tb-float`).style.display = '';
    setFocused(true);
  };

  const getHistory = (id) => {
    GetHistory(id)
      .then((data) => {
        rteObj.current.hideInlineToolbar();
        setHistoryDetail(data);
        setShowHistoryDetail(true);
        showHistoryDiff(showDiffChecked);
      })
  }
  const getNarrative = () => {
    setShowSpinner(true);
    setSpinnerText('Load Narrative...')
    Get(props.data.pkid)
      .then((data) => {
        if (data) {
          setShowSpinner(false);
          setNarrativeText(data.narrativeText);
          setReadOnlyMode(false);
        }
      })
  };

  const lock = () => {
    hideToolBar();
    setShowSpinner(true);
    setSpinnerText(!lockFlag ? 'Locking...' : 'Unlocking...');
    Update({
      id: props.data.pkid,
      data: { NarrativeText: rteObj.current.getHtml() }
    }).then((data) => {
      Lock(props.data.pkid).then((data) => {
        setShowSpinner(false);
        setReadOnlyMode(!lockFlag);
        setLockFalg(!lockFlag);
        props?.onLock(props.data.pkid)
        setShowPopup(false);
      });
    });
    
  }

  const update = () => {
    setShowSpinner(true);
    setSpinnerText('Saving changes...')
    Update({
      id: props.data.pkid,
      data: {
        NarrativeText: rteObj.current.getHtml(),
        NarrativeTypeId: props.data.narrativeTypeId,
        PatientId: props.clientId
      }
    }).then((data) => {
      setShowSpinner(false);
    });
  }

  const setReadOnlyMode = (isReadOnly) => {
    if (!rteObj) return;
    rteObj.current.focusOut();
    if (isReadOnly) {
      rteObj.current.hideInlineToolbar();
      rteObj.current.inputElement.classList.add('edit-narrative-editor-lock')
    } else {
      rteObj.current.inputElement.classList.remove('edit-narrative-editor-lock')
    }
    rteObj.current.readonly = isReadOnly;
  }

  const content = () => {
    return (
      <div className="dialogContent">
        <span className="dialogText">
          How can we write this section better?
        </span>
        <textarea style={{
          borderWidth: 1,
          borderColor: '#bdbdbd',
          width: '100%',
          padding: 5,
          borderRadius: 5,
          marginTop: 10
        }}
          onChange={e => setFeedback(e.target.value)}
          name="feedback" placeholder="Type your feedback here..." rows={4} cols={40} />
      </div>
    );
  }

  const FeedbackContent = () => {
    function selectRadio(selectedRadio) {
      const radios = document.querySelectorAll('input[name="feedbackOption"]');
      radios.forEach(radio => {
          radio.checked = false;
      });
      selectedRadio.checked = true;
    }
    return (
      <div className="dialogContent">
        <h2 className="dialogText">Share your feedback</h2>
        <form>
          {["Not accurate", "Missing Details", "Too Long", "Too Short", "Other"].map(option => (
            <label key={option}>
              <input
                type="radio"
                name="feedbackOption"
                value={option}
                onChange={(e) => {
                  setFeedbackOption(e.target.value);
                }}
                onClick={(e) => {selectRadio(e.currentTarget);}}
                defaultChecked={option == feedbackOption}
              />
              <span> {option}</span>
              <br/>
            </label>
          ))}
          <br />
          <textarea
            style={{
              borderWidth: 1,
              borderColor: '#bdbdbd',
              width: '100%',
              padding: 5,
              borderRadius: 5,
              marginTop: 10,
            }}
            onChange={(e) => setComments(e.target.value)}
            name="feedback"
            placeholder="Type your feedback here..."
            rows={4}
            cols={40}
          />
        </form>
      </div>
    );
  };
  
  const progress = () => {
    PollProgress(props.data.pkid)
      .then((data) => {
        if (data?.percent < 100) {
          setSpinnerText(data.message);
          setTimeout(() => {
            progress();
          }, 5000);
        } else {
          setShowSpinner(false);
          getNarrative();
        }
      });
  };

  const showHistoryDiff = (isChecked, update = false) => {
    if (isChecked) {
      let orgText = rteObj?.current?.getText();
      let oldText = divHisRef?.current?.innerText;
      var dmp = new DiffMatchPatch();
      var diff = dmp.diff_main(orgText, oldText);
      dmp.diff_cleanupSemantic(diff);
      const diffHtml = dmp.diff_prettyHtml(diff);
      divHisTextRef.current.innerHTML = diffHtml;
      divHisRef.current?.classList.add('hidden');
      divHisTextRef?.current?.classList.remove('hidden');
    } else {
      divHisRef?.current?.classList.remove('hidden');
      divHisTextRef?.current?.classList.add('hidden');
    }
    if (update) setShowDiffChecked(!showDiffChecked);
  }

  const hideToolBar = () => {
    document.querySelector(`#${props.data.narrativeTypeCode} .e-rte-tb-float`).style.display = 'none';
  }

  let buttons = [
    {
      click: () => {
        setShowSpinner(true);
        setSpinnerText("Rewriting...");
        hideToolBar();
        ReProcess({
          id: props.data.pkid,
          data: { NarrativeText: feedback }
        }).then((data) => {
          if (data?.status) {
            setTimeout(() => {
              progress();
            }, 5000);
          }
        });

        setopenDialog(false);
      },
      buttonModel: {
        isPrimary: true,
        className: "test",
        content: 'OK',
      },
    }, {
      click: () => {
        setopenDialog(false);
      },
      buttonModel: {
        isPrimary: false,
        content: 'Cancel',
      },
    },
  ];

  let feedbackButtons = [
    {
      click: () => {
        setShowSpinner(true);
        setSpinnerText("Submitting...");
        hideToolBar();
        setShowFeedbackModal(false);
        Feedback(pkid, feedbackOption, comments, rating)
        .then(() => {
          setShowSpinner(false);
          setSpinnerText("");    
          let stars = document.querySelectorAll('.star');
          stars.forEach((star, index) => {
            if (star.id != pkid) return;
            star.classList.remove('star-focused');
            if (index % 5 < rating) star.classList.add('star-focused');
          })
        });
      },
      buttonModel: {
        isPrimary: true,
        className: "test",
        content: 'Submit Feedback',
      },
    }, {
      click: () => {
        setShowFeedbackModal(false);
      },
      buttonModel: {
        isPrimary: false,
        content: 'Cancel',
      },
    },
  ];

  return (
    <div ref={divRef} id={props.data.narrativeTypeCode} className="edit-narrative-editor">
      {showHistoryDetail && (
        <div className="history-detail">
          <div className="mb-2" style={{ borderBottom: '1px solid #b4aab6', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ alignself: 'center' }}>
              <div className="input-group">
                <input type="checkbox" checked={showDiffChecked} onChange={() => {
                  showHistoryDiff(!showDiffChecked, true);
                }} />
                <div style={{ alignSelf: 'center' }} className="inline ml-2">Show Changes</div>
              </div>
            </div>
            <div style={{ alignSelf: 'center' }}>
              <i className={historyNavIndex == 0 ? "hidden" : "fas fa-chevron-left mr-2"} style={{ border: '1px solid #8080809e', padding: '5px', borderRadius: '5px' }} onClick={() => { getHistory(props.data.history[historyNavIndex - 1].pkid); setHistoryNavIndex(historyNavIndex - 1); }}></i>
              <i className={historyNavIndex + 1 < props.data.history.length ? "fas fa-chevron-right mr-3" : "hidden"} style={{ border: '1px solid #8080809e', padding: '5px', borderRadius: '5px' }} onClick={() => { getHistory(props.data.history[historyNavIndex + 1].pkid); setHistoryNavIndex(historyNavIndex + 1); }}></i>
            </div>
            <div class="m-2 text-right">
              <span style={{ color: '#b4aab6' }}>{historyDetail.changedOnDisplay}</span>
              <button type="button" class="btn-submit  p-0 ml-2" style={{ width: 150 }} onClick={() => {
                setNarrativeText(historyDetail.narrativeText);
                setShowHistoryDetail(false);
                update();
              }}>
                <div style={{ fontsize: 'small' }} className="d-inline">Apply </div>
              </button>
              <i className="fas fa-times-circle ml-2" style={{ fontsize: '25px', color: '#b4aab6' }} onClick={() => { setShowHistoryDetail(false) }}></i>
            </div>
          </div>
          <div ref={divHisRef} style={{ maxHeight: 600, overflowY: 'auto' }}>{parser(historyDetail.narrativeText)}</div>
          <div ref={divHisTextRef} style={{ maxHeight: 600, overflowY: 'auto' }}></div>
        </div>)}
      {openDialog && (<DialogComponent
        isModal={true}
        buttons={buttons}
        visible={openDialog}
        width="435px"
        minHeight="190px"
        content={content} >
      </DialogComponent>)}
      {showFeedbackModal && (<DialogComponent
        isModal={true}
        buttons={feedbackButtons}
        visible={showFeedbackModal}
        width="435px"
        minHeight="190px"
        content={FeedbackContent} >
      </DialogComponent>)}

      {showSpinner && (
        <div className="flex" style={{ background: '#fafafa', padding: 5 }}>
          <Spinner color="amber" />
          <span style={{ marginLeft: 5 }}>{spinnerText}</span>
        </div>)
      }
      {showPopup && (
        <div
          style={{
            position: 'absolute',
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
            padding: '10px',
            border: '1px solid #e0e0e0',
            backgroundColor: '#fff',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
            zIndex: 100000 // Ensure the popup is above other content
          }}
        >
          <button type="button" className="e-tbar-btn e-btn lock-btn" tooltiptext="Unlock" onClick={lock}>
            <i className="fas fa-lock"></i>
          </button>
        </div>
      )}

      {props.hasEdit ? (
        <RichTextEditorComponent
          ref={rteObj}
          inlineMode={inlineMode}
          onFocus={handleRteFocus}
          onClick={handleRteClick}
          onBlur={handleRteBlur}
          enableHtmlSanitizer={true}
          toolbarSettings={toolbarSettings}
          toolbarClick={handleToolbarClick}
          value={narrativeText}
          saveInterval={5 * 60 * 1000}
          change={() => { if (focused) update(); }}
          created={() => {
            
            hideToolBar();
            // Add event listener for the custom select dropdown
            const customSelect = document.querySelector('.my-custom-select');
            if (customSelect) {
              customSelect.addEventListener('change', handleDropdownChange);
            }
          }}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
      ) : (
        <div> {parser(narrativeText)}</div>
      )}
    </div>
  );
}
