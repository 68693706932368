import React, { useState, useEffect } from "react";
import { 
  Dialog, 
  DialogHeader, 
  DialogBody, 
  DialogFooter, 
  Button, 
  Spinner 
} from "@material-tailwind/react";
import { toastError, toastSuccess } from "../../../utils/toast"; // Toast notifications
import { UpdateNarrativeColor } from "../../../apis/Practice";
import { useParams } from "react-router-dom";

export const ColorPickerDialog = ({ open, onToggle, defaultColor, callBack, nid, isPracticeAdmin }) => {
  const [color, setColor] = useState(defaultColor);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientId = useParams("id").id;

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor, open]);

  const checkSuccess = (res) => {
    setIsSubmitting(false);
    if (!res["data"]["data"]["status"]) {
      toastError("Something went wrong.");
      callBack?.();
      return;
    } else {
      toastSuccess("Color updated successfully!");
      callBack?.();
      onToggle();
    }
  };

  const onSubmit = async (option) => {
    setIsSubmitting(true);
    try {
      const res = await UpdateNarrativeColor(nid, color, option, patientId);
      checkSuccess(res);
    } catch (error) {
      toastError("Failed to update color");
      checkSuccess(false);
    }
  };

  const handleColorChange = (event) => {
    event.stopPropagation();
    setColor(event.target.value);
  };

  return (
    <Dialog open={open} handler={onToggle} className="rounded-lg shadow-lg p-4">
      <DialogHeader className="text-lg font-semibold">Color Picker</DialogHeader>
      <DialogBody className="!max-h-[80vh] overflow-auto flex flex-col items-center">
        <input 
          type="color" 
          value={color} 
          onChange={handleColorChange} 
          className="mb-4 border-none w-20 h-20 rounded-full cursor-pointer transition duration-300 ease-in-out transform hover:scale-110"
        />
        <div 
          className="w-full h-20 rounded-lg border-2 border-gray-300 shadow-md flex items-center justify-center"
          style={{ backgroundColor: color }}
        >
          <span className="text-xl font-bold text-white">{color}</span>
        </div>
      </DialogBody>
      <DialogFooter className="justify-between">
        <Button variant="text" color="red" onClick={onToggle} className="mr-1 text-red-500">
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          className="bg-teal-500"
          onClick={ () => onSubmit(0) }
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Submit</span>
          )}
        </Button>
        <Button
          variant="gradient"
          color="green"
          className="bg-teal-500"
          onClick={ () => onSubmit(1) }
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Save default</span>
          )}
        </Button>
        {isPracticeAdmin && <Button
          variant="gradient"
          color="green"
          className="bg-teal-500"
          onClick={ () => onSubmit(2) }
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Save practice default</span>
          )}
        </Button>}
      </DialogFooter>
    </Dialog>
  );
};
