import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import { 
  Dialog, 
  DialogHeader, 
  DialogBody, 
  DialogFooter, 
  Button, 
  Spinner, 
} from "@material-tailwind/react";
import { getCroppedImg } from "../../../utils/canvasUtils"; // Utility to get the cropped image
import { toastError, toastSuccess } from "../../../utils/toast"; // Toast notifications
import { useParams } from "react-router-dom";

export const ImageDialog = ({ open, onToggle, defaultImage, callBack, updateImage, isPracticeAdmin, cropSize = { width: 400, height: 80 } }) => {
  const [image, setImage] = useState(defaultImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientId = useParams("id").id;

  useEffect(() => {
    setImage(defaultImage);
  }, [defaultImage, open]);

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const checkSuccess = (res, img, msg) => {
    if (!res) {
      setIsSubmitting(false);
      toastError(msg ?? "Something went wrong.");
      callBack(defaultImage);
      return;
    } else {
      setIsSubmitting(false);
      toastSuccess("Updated successfully!");
      callBack(URL.createObjectURL(img));
      onToggle();
    }
  };

  const onSubmit = async (option) => {
    setIsSubmitting(true);
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      if (croppedImage) {
        const res = await updateImage(croppedImage, option, patientId);
        checkSuccess(res, croppedImage);
      }
    } catch (error) {
      console.log(error)
      toastError("Failed to crop image");
      checkSuccess(false, defaultImage, "");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog open={open} handler={onToggle}>
      <DialogHeader>Image Editor</DialogHeader>
      <DialogBody className="!max-h-[80vh] overflow-auto">
        <input 
          type="file" 
          accept="image/*" 
          onChange={handleFileChange} 
          className="mb-4" 
        />
        <div className="w-full h-64 flex items-center justify-center">
          <div className="relative bg-gray" style={{width: cropSize.width + 'px', height: cropSize.height + 'px'}}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
              className="object-cover"
              cropSize={cropSize}
            />
          </div>
        </div>
      </DialogBody>
      <DialogFooter className="justify-between">
        <Button variant="text" color="red" onClick={onToggle} className="mr-1 text-red-500">
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          className=" bg-teal-500"
          onClick={ () => onSubmit(0) }
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Submit</span>
          )}
        </Button>
        <Button
          variant="gradient"
          color="green"
          className=" bg-teal-500"
          onClick={ () => onSubmit(1) }
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Save default</span>
          )}
        </Button>
        {isPracticeAdmin && <Button
          variant="gradient"
          color="green"
          className=" bg-teal-500"
          onClick={ () => onSubmit(2) }
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Save practice default</span>
          )}
        </Button>}
      </DialogFooter>
    </Dialog>
  );
};
