export const firstUpperCase = (val)=>{
  if(!val) return val;
  
  return val.slice(0,1).toUpperCase() + val.slice(1);
}

export const ConvDotPlotData = (data) => {
  if (Array.isArray(data) == false) {
    return null;
  }

  const themes = ["theme-blue", "theme-red", "theme-green", "theme-yellow"];

  const tables = data.map((sectionData, i) => {
    let sectionTitle = sectionData.title;

    let tableType = sectionData.type;

    
    // tableType = "bar";

    let table = {
      title: sectionTitle,
      type: tableType,
      reportType: sectionData.reportType,
      scale: {
        step: 10,
        noNumber: [],
        dotSections: [],
        labels: [],
        scaleLabel: "",
        atRisk: [],
        gradient : {},
        reversed: false//sectionData?.scaleType?.type == 'NEGATIVE'
      },
      sections: [],
    };

    if(sectionData.sections){
      table.scale.dotSections = sectionData.sections.map((section,index)=>{
        section.id = `id-chart-section-${i}-${index}`;
        return section;
      });
    }

    if(sectionData.gradient){
      table.scale.gradient = sectionData.gradient;
    }

  if(sectionData.data === null) return table;

    let ci = -1;
    for (let comp of sectionData.data) {
      ci++;

      let min = null;
      let max = null;

      let section = {
        theme: themes[ci % themes.length],
        bgColor: comp.backgroundColor,
        label: comp.label,
        composite: {
          label: comp.label,
          value: comp.confidenceInterval ?? comp.value,
          valueDisplay: comp.valueDisplay,
          confidence : comp.confidenceInterval,
          tooltip: (comp.tooltip === null || comp.tooltip === "") ? `Score: ${comp.value}` : comp.tooltip,
        },
        bars: [],
      };

      if (comp.subData && Array.isArray(comp.subData)) {
        for (let bar of comp.subData) {
          min = min == null ? bar.value : min > bar.value ? bar.value : min;
          max = max == null ? bar.value : max < bar.value ? bar.value : max;

          let _bar = {
            title: bar.title,
            label: bar.label,
            value: bar.confidenceInterval ?? bar.value,
            valueDisplay: bar.valueDisplay,
            confidence : bar.confidenceInterval,
            tooltip: (bar.tooltip === null || bar.tooltip === "") ? `Score: ${bar.value}` : bar.tooltip,
          };

          section.bars.push(_bar);
        }
        //section.composite.confidence = [min, max];
      }

      // section.composite.value = [min, max];
      
      table.sections.push(section);
    }

    table.description = sectionData?.description;
    table.scale.min = sectionData?.range?.min ?? 0;
    table.scale.max = sectionData?.range?.max ?? 100;
    table.scale.reversed = table.scale.min > table.scale.max;
    table.scale.min = table.scale.min==0 ? 1 : table.scale.min;
    table.scale.step = calculateStepAndSteps(table.scale.min,table.scale.max);

    return table;
  });

  const barChartData = {
    graphs: [],
  };

  const dotPlotsData = [];

  return tables;
};

const calculateStepAndSteps = (minValue, maxValue, desiredSteps = 10) => {

  const range = Math.abs(maxValue - minValue);
  let numberOfSteps = desiredSteps;

  // Calculate raw step size
  let rawStep = range / numberOfSteps;

  // Round to a nice step size
  const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)));
  const residual = rawStep / magnitude;

  let niceStep;

  if (residual < 1.5) {
      niceStep = 1;
  } else if (residual < 3) {
      niceStep = 2;
  } else if (residual < 7) {
      niceStep = 5;
  } else {
      niceStep = 10;
  }

  niceStep *= magnitude;

 
  return niceStep;
}

const getWhyBulletList = (text)=>{

    if(!text) return [];
    
    let _str = text.replaceAll("<br>", '').replaceAll("<br/>", '');
     _str = _str.replaceAll("**", '');

    let list = _str.split('- ');

    return list.filter(one=>!!one);

}

const getScoreList = (text)=>{

  if(!text) return [];
  
  let _str = text.replaceAll("<p>", '<br>').replaceAll("</p>", '<br>').replaceAll("<br/>", '<br>');
  let list = _str.split('<br>');
  var filter = list.filter(one=>!!one);
  var data = filter.map((_,i)=>{
    let strList = _.split(':');
    return {name: strList[0].trim(), value: Math.floor(strList[1].trim())};
  })

  return data;

}

const getWebList = (text)=>{

  if(!text) return [];
  
  let _str = text.replaceAll("<p>", '<br>').replaceAll("</p>", '<br>').replaceAll("<br/>", '<br>');
  let list = _str.split('<br>');
  var filter = list.filter(one=>!!one);
  var data = filter.map((_,i)=>{
    let domain = _.replace(/.+\/\/|www.|\..+/g, '');
    let url = _.split('https')[1]
    return {name: domain, value: 'https' + url};
  })

  return data;

}

export const ConvNarrativeData = (data) => {
  if (!Array.isArray(data)) return null;

  const NarrativeTypeCodes = [
    "WHY",
    "WHYBULLET",
    "GOINGWELLHEAD",
    "GOINGWELL",
    "POSTATEMENT",
    "CHALLENGEVIS",
    'DIAGVIS',  
    'RECVIS',
    'TOTALSCOREVIS',
    'RESOURCESVIS'
  ];

  let narrativeData = {};

  for (let one of data) {
    if (NarrativeTypeCodes.includes(one.narrativeTypeCode)) {

        if(one.narrativeTypeCode == 'WHYBULLET') {

            narrativeData[one.narrativeTypeCode] = {
                desc: one.narrativeText,
                list:getWhyBulletList(one.narrativeText),
                name: one.narrativeTypeName,
                code: one.narrativeTypeCode,
                pkid: one.pkid,
              };
        }else if(one.narrativeTypeCode == 'TOTALSCOREVIS'){
          narrativeData[one.narrativeTypeCode] = {
            desc: one.narrativeText,
            list:getScoreList(one.narrativeText),
            name: one.narrativeTypeName,
            code: one.narrativeTypeCode,
            pkid: one.pkid,
          };
        }else if(one.narrativeTypeCode == 'RESOURCESVIS'){
          narrativeData[one.narrativeTypeCode] = {
            desc: one.narrativeText,
            list:getWebList(one.narrativeText),
            name: one.narrativeTypeName,
            code: one.narrativeTypeCode,
            pkid: one.pkid,
            color: one.color,
            narrativeTypeId: one.narrativeTypeId,
          };
        }  
        else if(one.narrativeTypeCode == 'CHALLENGEVIS' || one.narrativeTypeCode == 'DIAGVIS' || one.narrativeTypeCode == 'RECVIS') {
            // const data = getChallengeList(one.narrativeText);
            narrativeData[one.narrativeTypeCode] = {
                desc: one.narrativeText,
                list: one.subList,
                name: one.narrativeTypeName,
                code: one.narrativeTypeCode,
                pkid: one.pkid,
                narrativeTypeId: one.narrativeTypeId,
                color: one.color,
                imageUrl: one.imageUrl,
              };
        } else {
            narrativeData[one.narrativeTypeCode] = {
                desc: one.narrativeText.replaceAll("<br/>", '').replaceAll("<br>", ''),
                name: one.narrativeTypeName,
                code: one.narrativeTypeCode,
                pkid: one.pkid,
                narrativeTypeId: one.narrativeTypeId,
                color: one.color,
                imageUrl: one.imageUrl,
              };
        }

      
    }
  }

  return narrativeData;
};

export const convertNarrativeData = (data) => {
  if (!Array.isArray(data)) return null;

  const NarrativeTypeCodes = [
    "SUM",
    "RULEDOUTDIAGNAR",
    "OVSTRENGTH",
    "GOINGWELL",
    "OVCHAL",
    "CHALBACK",
    'DIAGVIS',  
    'RECVIS',
    'TOTALSCOREVIS',
    'RESOURCESVIS'
  ];

  let narrativeData = {};

  for (let one of data) {
      
    if (NarrativeTypeCodes.includes(one.narrativeTypeCode)) {

            narrativeData[one.narrativeTypeCode] = {
                desc: one.narrativeText,
                name: one.narrativeTypeName,
                code: one.narrativeTypeCode,
                pkid: one.pkid,
            }
      }
  }

  return narrativeData;
};