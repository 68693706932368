import React, { useEffect, useState } from "react";
import { getUrlParameter } from "../../../utils/index.js";

import {
  IconButton,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Textarea,
  Spinner,
  Input,
} from "@material-tailwind/react";
import { UpdateNarrative } from "../../../apis";
import { useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../../utils/toast";
import { BiTrash } from "react-icons/bi";
import clsx from "clsx";
import { firstUpperCase } from "../../../utils/convert";

export const WhyDescDialog = ({
  open,
  initValue,
  dlgTitle = "Edit",
  data,
  onToggle,
  isList = false,
  isSimpleList = true,
  listItemFields = ["title", "description"],
  listItemInputTypes = ["input", "textarea"],
  callBack,
}) => {
  const [value, setValue] = useState(initValue);

  const { id: clientId } = {id: useParams("id").id};

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onChangeListItem = (e, val, index, itemField = "title") => {
    if (isSimpleList) {
      let _temp = [...value];

      _temp[index] = e.target.value;

      setValue(_temp);
    } else {
      let _temp = [...value];
      _temp[index][itemField] = e.target.value;

      console.log('>>> new item list:', {_temp})
      setValue(_temp);
    }
  };

  useEffect(() => {
    setValue(initValue);
  }, [initValue, open]);

  const checkSuccess = (res, msg) => {
    if (!res) {
      setIsSubmitting(false);
      toastError(msg ?? "Something went wrong.");
      callBack?.(false);
      return;
    } else {
      setIsSubmitting(false);
      toastSuccess("Updated successfully!");
      callBack?.(true);
      onToggle();
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    if (!isList) {
      const { data: res, msg } = await UpdateNarrative(clientId, {
        pkid: data.pkid,
        narrativeText: value,
        narrativeTypeCode: data.code,
        narrativeTypeName: data.name,
        subList: data.list,
      });
      checkSuccess(res, msg);
    }

    if (isList) {
      if (isSimpleList) {
        const listData = "- " + value.join("- ");
        const { data: res, msg } = await UpdateNarrative(clientId, {
          pkid: data.pkid,
          narrativeText: listData,
          narrativeTypeCode: data.code,
          narrativeTypeName: data.name,
          subList: data.list,
        });
        checkSuccess(res, msg);
      } else {
        console.log('>>> payload subList', {value})
        const { data: res, msg } = await UpdateNarrative(clientId, {
          pkid: data.pkid,
          narrativeText: data.desc,
          narrativeTypeCode: data.code,
          narrativeTypeName: data.name,
          subList: value,
        });
        checkSuccess(res, msg);
      }
    }
  };

  const onAddItem = () => {
    if (isSimpleList) {
      let _temp = value ? [...value, ""] : [""];
      setValue(_temp);
    } else {
      let _temp = value ? [...value] : [];
      let emptyObject = {};
      listItemFields.forEach((key, index) => {
        emptyObject[key] = "";
      });
      _temp.push(emptyObject);
      setValue(_temp);
    }
  };

  const onRemoveItem = (index) => {
    let _temp = value.filter((val, i) => i != index);
    setValue(_temp);
  };

  return (
    <Dialog open={open} handler={onToggle}>
      <DialogHeader>{dlgTitle}</DialogHeader>
      <DialogBody className="!max-h-[80vh] overflow-auto">
        {!isList && (
          <Textarea
            label=""
            className=" h-[300px]  max-h-[90vh] text-black"
            value={value}
            onChange={onChange}
          />
        )}

        {isList &&
          value?.map((val, index) => {
            return (
              <div className="w-full my-2 relative group" key={"wdde-" + index}>
                {!isSimpleList &&
                  listItemFields.map((field, fIndex) => {
                    let listItemInputType = listItemInputTypes[fIndex];

                    if (listItemInputType == "input") {
                      return (
                        <div
                          key={"vlik-" + index + "-" + fIndex}
                          className="my-2"
                        >
                          <Input
                            label={firstUpperCase(field + "-" + (index + 1)) }
                            className="  text-black "
                            value={val[field]}
                            onChange={(e) =>
                              onChangeListItem(e, val, index, field)
                            }
                          />
                        </div>
                      );
                    } else if (listItemInputType == "textarea") {
                      return (
                        <div
                          key={"vlik-" + index + "-" + fIndex}
                          className="my-2"
                        >
                          <Textarea                            
                            label={firstUpperCase(field + "-" + (index + 1)) }
                            className=" h-[60px] max-h-[100px] text-black"
                            value={val[field]}
                            onChange={(e) =>
                              onChangeListItem(e, val, index, field)
                            }
                          />
                        </div>
                      );
                    }
                  })}

                {isSimpleList && (
                  <Textarea
                    label={"Item-" + (index + 1)}
                    className=" h-[60px]  max-h-[100px] text-black"
                    value={val}
                    onChange={(e) => onChangeListItem(e, val, index)}
                  />
                )}

                <div className="absolute top-1 right-1 hidden group-hover:block">
                  <IconButton
                    size="sm"
                    color="black"
                    data-ripple-light="true"
                    data-dialog-target="dialog"
                    className={clsx(
                      " bg-red-mid w-3 h-3 px-3 ",
                      "text-white text-md"
                    )}
                    onClick={() => onRemoveItem(index)}
                  >
                    <BiTrash />
                  </IconButton>
                </div>
              </div>
            );
          })}

        {isList && (
          <div className="my-2 flex flex-row ">
            <Button
              variant="gradient"
              color="green"
              size="sm"
              className=" bg-cyan-500"
              onClick={onAddItem}
            >
              <span>+ Add Item</span>
            </Button>
          </div>
        )}
      </DialogBody>
      <DialogFooter className=" justify-between">
        <Button
          variant="text"
          color="red"
          onClick={onToggle}
          className="mr-1  text-red-mid"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          className=" bg-teal-500"
          onClick={onSubmit}
        >
          {isSubmitting ? (
            <Spinner className="h-5 w-5 mx-auto text-blue-500" color="white" />
          ) : (
            <span>Submit</span>
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
