import clsx from "clsx";

const findClosestPair = (arr, target) => {
  if (arr.length < 2) {
      throw new Error('Array must contain at least two elements');
  }

  let lowerBound = null;
  let upperBound = null;

  for (const num of arr) {
      if (num < target) {
          if (lowerBound === null || num > lowerBound) {
              lowerBound = num;
          }
      } else if (num > target) {
          if (upperBound === null || num < upperBound) {
              upperBound = num;
          }
      }
  }

  if (lowerBound !== null && upperBound !== null) {
      return [lowerBound, upperBound];
  } else {
      return [];
  }
}


const findClosestNumber = (arr, target) => {
  if (arr.length === 0) {
      throw new Error('Array is empty');
  }

  let closest = arr[0];
  let smallestDifference = Math.abs(arr[0] - target);

  for (const num of arr) {
      const currentDifference = Math.abs(num - target);
      if (currentDifference < smallestDifference) {
          closest = num;
          smallestDifference = currentDifference;
      }
  }

  return closest;
}

export default function RenderGraphScaleItems(
    // el,
    {
      min = 0,
      max = 100,
      step = 10,
      noNumber = [],
      dotSections = [],
      reversed = false,
      scaleLabel = null,
      labels,
    }
  ) {

    let scaleItems = Math.floor(Math.abs(max - min) / step);

    var arr = new Array(scaleItems + 1).fill(1).map((_, i) => {
      let num = min + step * i;
      if (reversed || min > max) {
        num = min - step * i;
      }
      return num;
    });

    let showDots = []
    if(dotSections && dotSections.length > 0){
      const addedNumbers = new Set();
      showDots = dotSections.map((section,index)=>{
        const closestNum = findClosestNumber(arr, section.range.max);
        var numToAdd = closestNum;

        if(addedNumbers.has(closestNum)){
          numToAdd = section.range.max;
          arr.push(numToAdd);
        }
        addedNumbers.add(numToAdd);
    
        return { num: numToAdd, id: section.id };
      });
      arr.sort((a,b)=> a-b);
    }

    return (
      <>
        {arr.map((_, i) => {
          let num = _;
  
          let inner = null;
  
          if (!noNumber.includes(num)) {
            inner = <strong> {num} </strong>;
          }
  
          var foundElement = showDots.find(item => item.num === num);
          return (
            <div
              key={"si-" + i}
              className={clsx(
                `graph-scale-item `,
                foundElement ? `visible-bar ${foundElement.id}` : ""
              )}
            >
              {inner}
            </div>
          );
        })}
      </>
    );
  }