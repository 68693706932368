import React, { useState, useEffect, useRef } from "react";
import { PlaceHolder } from "./PlaceHolders";
import { useParams } from "react-router-dom";
import { GetNarratives } from "../../apis/Narratives";
import SpinnerPage from "../SpinnerPage";


function UnUsedNarratives() {

  let portal = process.env.REACT_APP_BASEURL;
  let env = process.env.REACT_APP_ENV;

  const clientId = useParams("id").id;
  const evalId = useParams("evalId").evalId;
  const [isOpen, setIsOpen] = useState(true);
  const [toc, setToc] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedData = localStorage.getItem(`unUsedNarrative${clientId}`);
    if (storedData) {
      var storedJson = JSON.parse(storedData);
      var filter = storedJson.data.filter(item=>!item.hide);
      setClientInfo(storedJson.clientInfo);

      GetNarratives(clientId)
      .then((data) => {
        var unusedNar = []
       data?.forEach(ele => {
        if(filter.some(item=> item.narrativeTypeCode == ele.narrativeTypeCode) )
          {
            unusedNar.push(ele);
        }
       });
       setData(unusedNar);
       setToc(getToc(unusedNar));
       setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }, []);

  const onLock = (id) => {
    var tt = toc.map(t => { if (t.id == id) t.lock = !t.lock; return t });
    setToc(tt);
  }

  const replacePlaceHolders = (data) => {
    return (
      <div>
        <b>{data.narrativeTypeName}</b>
        <PlaceHolder
          data={data}
          clientId={clientId}
          hasEdit={true}
          onLock={onLock} />
      </div>
    );
  }

  const getToc = (data) => {
    let topic = [];
    data.forEach(ele => {
      topic.push({
        narrativeTypeCode: ele.narrativeTypeCode,
        narrativeTypeName: ele.narrativeTypeName,
        lock: ele.lock,
        id: ele.pkid
      })
    });

    return topic;
  }

  const scrollToElementById = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'auto' });
      }, 300);
      setTimeout(() => {
        document.getElementsByClassName('scroll-editor-wrapper')[0].scrollBy({
          top: -100,
          behavior: 'auto'
        });
      }, 300);
    }
  };

  const handleToggle = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className="home flex-container">
      {isLoading && (<SpinnerPage></SpinnerPage>)}
      <div className="flex-left">
        <div class="position-sticky">
          {env == "DEV" && (
            <div class="mt-2">
              <span class="env-block"> DEV</span>
            </div>)}
          <div class="list-group list-group-flush mx-3 mt-4">
            <img src="/assets/images/reverb/ReverbLogoAndText.svg" class="mb-3 ml-4" style={{ width: '70%' }} />

            <a href={portal} title="Home" class="dropdown-item side-item ml-4 side-item-active">
              <img src="/assets/images/icons/Dashboard 3.svg" class="fas side-panel-icon mr-3" /> Home
            </a>
            {clientInfo?.isPracticeAdmin == true && (
              <a class="dropdown-item side-item ml-4 " href={`${portal}ManagePractice`}>
                <i class="fas fa-users-cog side-panel-icon mr-3"></i>
                Manage Practice
              </a>)}
            <a class="dropdown-item side-item ml-4 " href={`${portal}ReportType`}>
              <img src="/assets/images/icons/File Report.svg" class="fas side-panel-icon mr-3" />
              Supported Reports</a>
            <a class="dropdown-item side-item ml-4" href="mailto:support@testrev.com">
              <img src="/assets/images/icons/Customer Support 1.svg" class="fas side-panel-icon mr-3" />
              Support</a>
            <a class="dropdown-item side-item ml-4" href={`${portal}Account/SignOut`}>
              <img src="/assets/images/icons/Logout 1.svg" class="fas side-panel-icon mr-3" />
              Log out</a>
          </div>
        </div>
      </div>
      <div className="flex-right">
        <div className={`collapsible-content ${isOpen ? 'open' : ''}`}>
          <div style={{ textAlign: 'right' }}>
            <button style={{
              borderRadius: '5px',
              borderWidth: '1px',
              borderColor: '#EAA700',
              margin: '5px'
            }} onClick={handleToggle}>
              <i class={isOpen ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'}></i>
            </button>
          </div>
          <div className="edit-narrative-header">
            <div class="grid grid-cols-2 grid-flow-row gap-5">
              <div className="client-detail-card" style={{paddingTop: "20px"}}>
                <table>
                  <tr>
                    <td className="text-right">First Name:</td>
                    <td className="pl-[10px]">{clientInfo?.firstName}</td>
                  </tr>
                  <tr>
                    <td className="text-right">Last Name:</td>
                    <td className="pl-[10px]">{clientInfo?.lastName}</td>
                  </tr>
                  <tr>
                    <td className="text-right">DOB:</td>
                    <td className="pl-[10px]">{clientInfo?.dob}</td>
                  </tr>
                  <tr>
                    <td className="text-right">Email:</td>
                    <td className="pl-[10px]">{clientInfo?.email}</td>
                  </tr>
                </table>
              </div>
              <div className="client-detail-card">
                <div className="quick-action-title">
                  <span>Quick Actions</span>
                </div>
                <div className="text-center">
                <button className="btn btn-primary narative-button mb-[5px] mt-[5px]" onClick={() => { window.location.href = `${portal}Batch/${clientId}` }}>Client detail</button>
                </div>
                <div className="text-center">
                <button className="btn btn-primary narative-button mb-[5px] mt-[5px]" onClick={() => { window.close() }}>Close Tab/Return to Edit Narrative</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edit-narrative-content grid grid-cols-5 gap-4">
          <div className="col-span-1" style={{ cursor: 'pointer' }}>
            <div className="custom-scroll" style={{ width: '200px', height: 'calc(100vh - 60px)' }}>
              {toc?.map(nar =>
                <div style={{ display: 'flex' }}> {nar.lock ? (<i style={{ alignContent: "center", flex: '0 0 14px', fontSize: '0.8em' }} className="fas fa-lock"></i>) : (<div style={{ flex: '0 0 14px' }}></div>)}
                  <div className="toc" onClick={() => scrollToElementById(nar.narrativeTypeCode)}>
                    {nar.narrativeTypeName}
                  </div></div>)}
            </div>
          </div>
          {!isLoading && (
          <div className="scroll-editor-wrapper custom-scroll col-span-4">
            <div className="editor-wrapper">
              {data?.map(data => replacePlaceHolders(data))}
            </div>
          </div>)}
        </div>
      </div>
    </div>
  );
}

export default UnUsedNarratives;
