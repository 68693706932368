
import useReportNarrativeStore from "../../store/report_narrative.store";


const ResourceSections = ({}) => {
  const narrativeData = useReportNarrativeStore((state) => state.data);
  const chList = narrativeData["RESOURCESVIS"]?.list;
  
    return (
      <>
        <section className="mb-[30px]">
          <div className="container">
            <div className="flex flex-col max-w-text">
              <h3 className="text-h3 mb-[30px]">Resources</h3>
            </div>
           
            <ul className="flex flex-row flex-wrap gap-[30px]">
             {  chList?.map((res, i) => (
                  <li className="resource-link" key={i}>
                  <span>
                    <a
                      className="font-semibold"
                      href={res.value}
                      target="_blank"
                    >
                      {res.name}
                    </a>
                  </span>
                  </li>
                )
              )
              }
          </ul>
          </div>
        </section>
      </>
    );
  };

  export default ResourceSections;