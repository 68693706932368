import React, { useEffect, useState } from "react";
import useReportClientStore from "../store/report_client.store";
import { SkeletonBar } from "./SkeletonBar";
import { IconButton } from "@material-tailwind/react";
import { clsx } from "clsx";
import { BiSolidPencil } from "react-icons/bi";
import { ImageDialog } from "./home/edit/ImageDialog";
import { UpdateVisualizationHeader } from "../apis/Practice";

const Header = ({ cid }) => {

  const client = useReportClientStore((state) => state.data);
  const isLoading = useReportClientStore((state) => state.isLoading);
  const [isEditingHeaderImage, setIsEditingHeaderImage] = useState(false);
  const fetchClientInfo = useReportClientStore((state) => state.fetchClientInfo);

  const [image, setImage] = useState(client?.headerImage ? `${client?.headerImage}` : "'/assets/images/leaves.jpg'");

  const onEditHeaderImage = () => {
    setIsEditingHeaderImage(isEditing => !isEditing);
  }
  const onUpdateCallback = async (img = undefined) => {
    await fetchClientInfo(cid);
    if (typeof(img) == String) setImage(img);
  }
  useEffect(() => {
    setImage(client?.headerImage ? `${client?.headerImage}` : "'/assets/images/leaves.jpg'");
  }, [client]);

  return (
    <>
      <header className="pb-7 bg-no-repeat bg-center bg-cover  theme-light  mb-[30px] pt-10 group " style={{backgroundImage: `url(${image})`}}>
        <div className="absolute top-2 right-2  hidden group-hover:block">
          <IconButton
            size="sm"
            color="black"
            data-ripple-light="true"
            data-dialog-target="dialog"
            className={clsx(
              " bg-teal-500 w-3 h-3 px-3 ",
              "text-white text-md"
            )}
            onClick={onEditHeaderImage}
          >
            <BiSolidPencil />
          </IconButton>

          <ImageDialog
            open={isEditingHeaderImage}
            defaultImage={image}
            onToggle={onEditHeaderImage}
            callBack={onUpdateCallback}
            updateImage={UpdateVisualizationHeader}
            isPracticeAdmin={client.isPracticeAdmin}
          />
        </div>
        <div className="container ">
          <div className="flex flex-row md:justify-end mb-[30px] md:mb-[80px] lg:mb-[105px]">

          </div>

          <div className=" grid-layout  justify-between">
            <div className="col-span-12 lg:col-span-6">
              <p className="text-h4 font-medium">Psychological Evaluation</p>

              <SkeletonBar rows={1} isLoading={isLoading} />
              {!isLoading && (
                <h1 className="text-serif-h1">
                  {client?.firstName} {client?.lastName}
                </h1>
              )}
            </div>

            <div className="col-span-12 lg:col-span-6 flex flex-wrap lg:items-end lg:justify-end flex-row gap-[30px]">
              <div>
                {!isLoading && (
                  <p className="text-nowrap">{client?.evalutationDate}</p>
                )}
                <SkeletonBar rows={1} isLoading={isLoading} />
                <p className="text-nowrap">
                  <strong>Evaluation Date</strong>
                </p>
              </div>

              <div>
                <SkeletonBar rows={1} isLoading={isLoading} />
                {!isLoading && (
                  <p className="text-nowrap">{client?.reportDate}</p>
                )}
                <p className="text-nowrap">
                  <strong>Report Date</strong>
                </p>
              </div>

              <div>
                <SkeletonBar rows={1} isLoading={isLoading} />
                {!isLoading && (
                  <p className="text-nowrap">{client?.clinician}</p>
                )}
                <p className="text-nowrap">
                  <strong>Clinician</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="mb-[30px]">
        <div className="container flex justify-start">
          <SkeletonBar className={'!h-28 !w-52'} rows={1} isLoading={isLoading} />
          {!isLoading && (
            <img src={client?.logo} alt="Placeholder" className="h-auto" style={{maxHeight: '90px'}} />
          )}
        </div>
      </section>
    </>
  );
};

export default Header;
