import React, { useState } from "react";
import clsx from "clsx";

import {
  buildDotPlotText,
  CalcDataPoint,
} from "../utils";

import { Tooltip } from "@material-tailwind/react";

import ToolTipContent from './TooltipContent';
import CompositeTrack from './CompositeTrack';


const DotPlotSection = ({ section, min, max, image = false }) => {
  const [closed, setClosed] = useState(false);

  const compositeTitleEl = (section) => {
    const compositeText = buildDotPlotText(
      section.composite.value,
      section.composite.confidence
    );

    return (
      <div className="dot-plot-item-text">
        <strong> {section.composite.label} </strong>
        {compositeText && compositeText !== "" && <span>{compositeText}</span>}
      </div>
    );
  };

  const BarItem = ({ bar, image = false }) => {
    const [openToolTip, setOpenToolTip] = useState(false);

    let confidenceEl = null;

    if (bar.confidence) {
      let conf = null;
      let confMin = null;
      let confMax = null;

      if (Array.isArray(bar.confidence)) {
        [confMin, confMax] = bar.confidence;
      } else {
        conf = bar.confidence;
      }

      const { left, width } = CalcDataPoint(min, max, confMin, confMax, conf);

      confidenceEl = (
        <div
          className={`dot-plot-score dot-plot-thin `}
          style={{ left, width }}
        ></div>
      );
    }

    let valueEl = null;
    let tLeft = null;
    let tWidth = null;

    if (bar.value) {
      let val = null;
      let valMin = null;
      let valMax = null;

      if (Array.isArray(bar.value)) {
        [valMin, valMax] = bar.value;
      } else {
        val = bar.value;
      }

      const { left, width } = CalcDataPoint(min, max, valMin, valMax, val);

      tLeft = left;
      tWidth = width;

      if (bar.tooltip && bar.tooltip.length) {
        valueEl = (
          <Tooltip
            className={"bg-white text-black shadow-md shadow-blue-gray-400 "}
            open={openToolTip}
            content={<ToolTipContent tooltip={bar.tooltip} />}
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0, y: 25 },
            }}
          >
            <div
              className={`dot-plot-score dot-plot-thick `}
              style={{ left, width, backgroundColor:section.bgColor }}
              data-tippy-content={bar.tooltip}
            ><div style={{ left:'10px', top: image ? '-15px' : '-10px', position:'absolute' }}>{bar.valueDisplay}</div></div>
          </Tooltip>
        );
      } else {
        valueEl = (
          <div
            className={`dot-plot-score dot-plot-thick `}
            style={{ left, width, backgroundColor:section.bgColor }}
          ><div style={{ left:'10px', top: image ? '-15px' : '-10px', position:'absolute' }}>{bar.valueDisplay}</div></div>
        );
      }
    }

    const showToolTip = () => {
      setOpenToolTip(true);
    };

    const hideToolTip = () => {
      setOpenToolTip(false);
    };
    let barTrack = (
      <div
        className="dot-plot-track"
        onMouseEnter={showToolTip}
        onMouseLeave={hideToolTip}
      >
        {confidenceEl}
        {valueEl}
      </div>
    );

    let labelEl = null;
    if (bar.label) {
      let barTitle = bar.label;
      const barText = buildDotPlotText(bar.value, bar.confidence);

      labelEl = (
        <div className={`dot-plot-item-text `}>
          {barTitle} {barText && <span>{barText}</span>}
        </div>
      );
    }

    if (bar.tooltip && bar.tooltip.length) {
      return (
        <div className="dot-plot-item " data-tippy-trigger="">
          {labelEl}
          {barTrack}
        </div>
      );
    } else {
      return (
        <div className="dot-plot-item">
          {labelEl}
          {barTrack}
        </div>
      );
    }
  };

  const barItems = (section , image = false) => {
    if (!section.bars || !section.bars.length) return null;

    return (
      <div className={`dot-plot-items`}>
        {section.bars.map((bar, index) => {
          return <BarItem key={"key-bar-" + index} bar={bar} image={image} />;
        })}
      </div>
    );
  };

  const onClickComposite = () => {
    setClosed(!closed);
  };

  return (
    <div
      className={clsx(
        `dot-plot-section`,
        section.theme ? section.theme : "",
        closed ? "closed" : ""
      )}
      style={{backgroundColor:`${section.bgColor}1a`}}
    >
      {section.composite && section.composite.label ? (
        section.composite.tooltip && section.composite.tooltip.length ? (
          <div
            className={`dot-plot-composite `}
            data-tippy-trigger=""
            onClick={onClickComposite}
          >
            {compositeTitleEl(section)}
            <CompositeTrack section={section} min={min} max={max} image={image} />
          </div>
        ) : (
          <div className={`dot-plot-composite `} onClick={onClickComposite}>
            {compositeTitleEl(section)}
            <CompositeTrack section={section} min={min} max={max} image={image} />
          </div>
        )
      ) : null}

      {/* bar items */}
      {barItems(section, image)}
    </div>
  );
};

export default DotPlotSection;
