import React from "react";

const ToolTipContent = ({ tooltip }) => {
  const tips = tooltip.split("<br>");

  return (
    <div>
      {tips.map((tip, i) => {
        return <div key={"tk-" + i}>{tip}</div>;
      })}
    </div>
  );
};

export default ToolTipContent;
