import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

export async function GetScoreReports(cid) {
  try {
    let key = localStorage.getItem('authToken');
    const data = await axios.get(baseUrl + `api/Report/Scores/${cid}`, {
      headers: { "Authorization": key },
    });
    return { data };
  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

export async function GetNarrative(cid) {
  try {
    let key = localStorage.getItem('authToken');
    const data = await axios.get(baseUrl + `api/Narrative/Visualization/${cid}`, {
      headers: { "Authorization": key },
    });
    return { data };
  } catch (ex) {
    console.log('erro'+ex)

    return { data: null, msg: ex.message };
  }
}
export async function UpdateNarrative(
  cid,
  { pkid, narrativeText, narrativeTypeCode, narrativeTypeName, subList }
) {
  const data = {
    pkid,
    narrativeText,
    narrativeTypeCode,
    narrativeTypeName,
    subList: narrativeTypeCode == 'WHYBULLET' ? null : subList,
  };

  try {
    let key = localStorage.getItem('authToken');
    const response = await axios.patch(
       `${baseUrl}api/Narrative/Visualization/${cid}`,
      data,
      {
        headers: { "Authorization": key },
      }
    );

    return { data: response.data };

  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

export async function CheckSession() {
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.withCredentials = true;
    const response = await axios.get(
       `${baseUrl}Account/CheckSession`,
      {
        headers: { "Authorization": key },
      }
    );
    return { data: response.data };

  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

export async function PingHome() {
  try {
    let key = localStorage.getItem('authToken');
    const response = await axios.get(
       `${baseUrl}Home`,
      {
        headers: { "Authorization": key },
      }
    );

    return { data: response.data };

  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}