import React, { useState, useEffect, useRef } from 'react';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { ExportToPdf, ExportToWord, FinalizeTemplate, GetNarratives, GetTemplate, GetFinalizeTemplate, GetSfdtTemplate } from "../../apis/Narratives";
import { useLocation, useNavigate } from 'react-router-dom';
import { GetPracticeInfo } from '../../apis/Practice';
import SpinnerPage from '../SpinnerPage';
import { toastError, toastSuccess } from '../../utils/toast';
import { GetScoreReports } from '../../apis';
import useReportTableStore from '../../store/report_table.store';
import { ConvDotPlotData } from '../../utils/convert';
import AcademicChartSection from '../home/AcademicChartSection';
import html2canvas from 'html2canvas';
import { toCanvas, toPng } from 'html-to-image';

DocumentEditorContainerComponent.Inject(Toolbar);

function Finalize() {
    const hostUrl = process.env.REACT_APP_BASEURL;
    const location = useLocation();
    const state = location.state || {};
    let { clientId, template, evalId, finalizeId } = state;
    const [isLoading, setIsLoading] = useState(true);
    const [isChartLoading, setChartIsLoading] = useState(true);
    const [filename, setFilename] = useState("fileName");
    const [message, setMessage] = useState("loading...");

    const editor = useRef(null);
    const navigate = useNavigate()
    const updateReportTables = useReportTableStore((state) => state.update);

    const processBatchImageToHtml = async (batch) => {
        let promises = []
        batch.map((table) => {
            const element = document.getElementById(`${table.reportType}`);
            if (element) {
                promises.push(toCanvas(element)
                .then(function (canvas) {
                    const width = canvas.width;
                    const height = canvas.height;
                    const aspectRatio = width / height;
                    const maxWidth = 626;
                    const maxHeight = 900;

                    let newWidth = width;
                    let newHeight = height;

                    if (width > maxWidth) {
                        newWidth = maxWidth;
                        newHeight = maxWidth / aspectRatio;
                    }

                    if (newHeight > maxHeight) {
                        newHeight = maxHeight;
                        newWidth = newHeight * aspectRatio;
                    }

                    const imgData = canvas.toDataURL('image/png');
                    element.parentNode.remove();
                    editor.current.documentEditor.search.find(`<<CHART:${table.reportType}>>`, 'None');
                    if (editor.current.documentEditor.search.searchResults.index != -1) {
                        editor.current.documentEditor.editor.insertImage(`${imgData}`, newWidth, newHeight);
                    }
                }));
            } else {
                editor.current.documentEditor.search.findAll(`<<CHART:${table.reportType}>>`, 'None');
                editor.current.documentEditor.search.searchResults.replaceAll('');
            }
        });
        await Promise.all(promises);
    };

    const insertBase64Image = async (tables) => {

        const batchSize = 10;
        for (let i = 0; i < tables?.length; i += batchSize) {
            const batch = tables?.slice(i, i + batchSize);
            await processBatchImageToHtml(batch);
        }

        setIsLoading(false);
        setMessage('');
        
        editor.current.documentEditor.scrollToPage(1);
    };

    useEffect(() => {
        rendereComplete();
        const fetchData = async () => {
            try {
                if (!!finalizeId && finalizeId != '00000000-0000-0000-0000-000000000000') {
                    GetFinalizeTemplate(finalizeId).then((data) => {
                        editor.current.documentEditor.open(data);
                        setIsLoading(false);
                    });
                } else {
                    GetSfdtTemplate(template, clientId).
                        then(async (data) => {
                            editor.current.documentEditor.open(data.html);
                            processProfile();
                            processScore();
                        });
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    async function asyncFindReplace(ele) {
        let text = ele.plainText;
        editor.current.documentEditor.search.findAll(`<<${ele.narrativeTypeCode}>>`, 'None');
        editor.current.documentEditor.search.searchResults.replaceAll(text);
    }

    async function processNarrative() 
    {
        setMessage('Processing Narratives...')
        GetNarratives(clientId)
            .then((data) => {
                data.forEach((item) => {
                    asyncFindReplace(item);
                })
                editor.current.documentEditor.scrollToPage(1);
                processScore()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function processProfile() 
    {
        setMessage('Processing Profile...')
        GetPracticeInfo(clientId)
            .then((response) => {
                let clientInfo = response.data.data;
                let filename = `${clientInfo.firstName}_${clientInfo.lastName}`;
                setFilename(filename);

                /*
                //Replace Client Info
                editor.current.documentEditor.search.findAll(`<<FIRSTNAME>>`, 'None');
                editor.current.documentEditor.search.searchResults.replaceAll(clientInfo.firstName);

                editor.current.documentEditor.search.findAll(`<<FORMALFIRST>>`, 'None');
                editor.current.documentEditor.search.searchResults.replaceAll(clientInfo.formalFirst);


                editor.current.documentEditor.search.findAll(`<<LASTNAME>>`, 'None');
                editor.current.documentEditor.search.searchResults.replaceAll(clientInfo.lastName);

                editor.current.documentEditor.search.findAll(`<<DOB>>`, 'None');
                editor.current.documentEditor.search.searchResults.replaceAll(clientInfo.dob);

                editor.current.documentEditor.search.findAll(`<<PRONOUN>>`, 'None');
                editor.current.documentEditor.search.searchResults.replaceAll(clientInfo.pronoun);
                
                processNarrative();*/
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function processScore() {
        
        setMessage('Processing Charts...')
        GetScoreReports(`${clientId}`).then(({ data, msg }) => {
            const plotData = ConvDotPlotData(data.data);
            updateReportTables(plotData);
            setChartIsLoading(false);
            setTimeout(() => {
                insertBase64Image(plotData);
            }, 5000);
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const exportContentToWord = () => {
        setIsLoading(true);
        let filename = document.getElementById('filename')?.value;
        let filenameDocx = `${filename}.docx`;

        editor.current.documentEditor
            .saveAsBlob("Sfdt")
            .then(async (sfdtBlob) => {
                ExportToWord(sfdtBlob, filenameDocx)
                    .then(({ blob, filenamewithEx }) => {
                        setIsLoading(false);
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = filenamewithEx;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('Fetch error:', error);
                    });
            });
    }

    function exportContentToPDF() {
        setIsLoading(true);
        let filename = document.getElementById('filename')?.value;
        let filenamePdf = `${filename}.pdf`;

        editor.current.documentEditor
            .saveAsBlob("Sfdt")
            .then(async (sfdtBlob) => {
                ExportToPdf(sfdtBlob, filenamePdf)
                    .then(({ blob, filenamewithEx }) => {
                        setIsLoading(false);
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = filenamewithEx;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('Fetch error:', error);
                    });
            });
    }

    async function handleFinalizeTemplate() {

        try {
            setIsLoading(true);
            editor.current.documentEditor
                .saveAsBlob("Sfdt")
                .then(async (blob) => {
                    FinalizeTemplate(blob, clientId, finalizeId).then((data) => {
                        toastSuccess("Saved successfully!");
                    });
                    setIsLoading(false);
                });
        } catch (ex) {
            setIsLoading(false);
            console.log("Error in saving template", ex);
            toastError("Error in saving. plese try again later");
        }
    }

    let toolbarOptions = [{
        tooltipText: 'Close',
        template: '<button title="Close" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" type="button" id="closebtn"><span class="e-btn-icon e-icons e-close e-icon-left"></span><span class="e-tbar-btn-text">' +
            'Close' +
            '</span>',
        id: 'closebtn',
        text: 'Close',
    },
    {
        tooltipText: 'Save',
        template: '<button title="Save" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" style="margin-left:0.5rem" type="button" id="savebtn"><span class="e-btn-icon e-icons e-de-save-icon e-icon-left"></span><span class="e-tbar-btn-text">' +
            'Save' +
            '</span>',
        id: 'savebtn',
        text: 'Save',
    },
    {
        tooltipText: 'Export to Pdf',
        template: '<button title="Export to Pdf" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" style="margin-left:0.5rem" type="button" id="pdfbtn"><span class="e-btn-icon e-icons e-export-pdf-1 e-icon-left"></span><span class="e-tbar-btn-text">' +
            'Pdf' +
            '</span>',
        id: 'pdfbtn',
        text: 'Pdf',
    },
    {
        tooltipText: 'Export to Docx',
        template: '<button title="Export to Docx" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" style="margin-left:0.5rem" type="button" id="docxbtn"><span class="e-btn-icon e-icons e-export-word-1 e-icon-left"></span><span class="e-tbar-btn-text">' +
            'Docx' +
            '</span>',
        id: 'docxbtn',
        text: 'Docx',
    },
        'Separator',
        'Undo',
        'Redo',
        'Separator',
        'Separator',
        'Image',
        'Table',
        'Hyperlink',
        'Bookmark',
        'TableOfContents',
        'Separator',
        'Header',
        'Footer',
        'PageSetup',
        'PageNumber',
        'Break',
        'Separator',
        'Find',
        'Separator'
    ];

    const rendereComplete = () => {
        window.onbeforeunload = function () {
            return "Want to save your changes?";
        };
        editor.current.documentEditor.pageOutline = "#E0E0E0";
        editor.current.documentEditor.acceptTab = true;
        editor.current.documentEditorSettings.showRuler = true;
        editor.current.documentEditor.resize();

        editor.current.documentChange = () => {
            editor.current.documentEditor.focusIn();
        };
    };

    const onToolbarClick = (args) => {
        if (args.item.id === 'savebtn') {
            handleFinalizeTemplate();
        }
        else if (args.item.id === 'docxbtn') {
            exportContentToWord();
        } else if (args.item.id === 'pdfbtn') {
            exportContentToPDF();
        } else if (args.item.id === 'closebtn') {
            navigate(`/editNarrative/${clientId}/${evalId}`)
        }
    };

    return (<div className='control-pane'>
        {isLoading && (<SpinnerPage message={message}></SpinnerPage>)}

        <input id="filename" type='hidden' value={filename}></input>
        <div className='control-section full-rte-mode' id="rteTools">
            <div className='rte-control-section'>
                <DocumentEditorContainerComponent id="container"
                    ref={editor}
                    style={{ display: "block" }}
                    height={"100vh"}
                    serviceUrl={hostUrl}
                    enableToolbar={true}
                    toolbarItems={toolbarOptions}
                    toolbarClick={onToolbarClick}
                    locale="en-US" />
            </div>
        </div>
        {!isChartLoading && (<AcademicChartSection image={true} />)}
    </div>);
}
export default Finalize;