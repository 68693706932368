import React, { useState, useEffect, useRef } from 'react';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { useLocation, useParams } from 'react-router-dom';
import SpinnerPage from '../SpinnerPage';
import { toastError, toastSuccess } from '../../utils/toast';
import { GetDocument, SaveDocument } from '../../apis/Practice';

DocumentEditorContainerComponent.Inject(Toolbar);

function EditDocument() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const fileURL = query.get('fileURL');
  const dictionaryId = query.get('dictionaryId');
  const id = useParams("id").id//query.get('id');
  const bankName = query.get('bankName');
  const editorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDocument = async () => {
      const response = await GetDocument(id);
        if (response) {
          editorRef.current.documentEditor.open(response)
        } else {
          toastError("Failed to load the document.");
        }
        setIsLoading(false);
    };

    fetchDocument();
  }, [fileURL]);

  const handleSaveDocument = async () => {
    const decodedUrl = decodeURIComponent(fileURL);
    const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1);
    setIsLoading(true);
    editorRef.current.documentEditor.saveAsBlob("Docx").then(blob => SaveDocument(blob, fileName,dictionaryId, id, bankName))
    .then(status => {
      if (status == 200) {
        toastSuccess("Document saved successfully!");
      } else {
        toastError("Failed to save the document.");
      }
    }).catch(err => {
      console.log("Error saving doc: ", err);
      toastError("Failed to save the document.");
    }).finally(() => {
      setIsLoading(false);
    });
  };

    let toolbarOptions = [{
      tooltipText: 'Close',
      template: '<button title="Close" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" type="button" id="closebtn"><span class="e-btn-icon e-icons e-close e-icon-left"></span><span class="e-tbar-btn-text">' +
          'Close' +
          '</span>',
      id: 'closebtn',
      text: 'Close',
    },
    {
        tooltipText: 'Save',
        template: '<button title="Save" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" style="margin-left:0.5rem" type="button" id="savebtn"><span class="e-btn-icon e-icons e-de-save-icon e-icon-left"></span><span class="e-tbar-btn-text">' +
            'Save' +
            '</span>',
        id: 'savebtn',
        text: 'Save',
    },
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'Separator',
    'Find',
    'Separator'
  ];

  const onToolbarClick = (args) => {
    if (args.item.id === 'savebtn') {
      handleSaveDocument();
    } else if (args.item.id === 'closebtn') {
      window.close();
    }
  };

  return (
    <div className='control-pane'>
      {isLoading && <SpinnerPage message="Loading document..." />}
      <div className='control-section full-rte-mode' id="rteTools">
        <div className='rte-control-section'>
          <DocumentEditorContainerComponent
            id="container"
            ref={editorRef}
            height="100vh"
            enableToolbar={true}
            toolbarItems={toolbarOptions}
            toolbarClick={onToolbarClick}
            locale="en-US"
          />
        </div>
      </div>
    </div>
  );
}

export default EditDocument;
