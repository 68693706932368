import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASEURL;

export const GetNarratives = async(clientId) => {
 try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl +`api/Narrative/all/${clientId}`);
    return response.data;

 } catch(error) {
    console.error('Error in fetching narrative data:' , error);
    throw error;
 }

}

export async function Get(id) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/Narrative/` + id);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function GetHistory(id) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/Narrative/History/` + id);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function Update({id,data}) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.patch(baseUrl + `api/Narrative/` + id,data);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function Complete(clientId) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(baseUrl + `api/Narrative/Complete/` + clientId);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function Lock(id) 
{
  try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(baseUrl + `api/Narrative/Lock/` + id);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function Feedback(id, feedbackOption, note, rate) 
{
  try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(baseUrl + `api/Narrative/Feedback/` + id, {
      feedbackOption,
      note,
      rate
    });
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function GetTemplates(clientId, evalId) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/ManageTemplate/Templates/${clientId}/${evalId}`);
    return response.data;
  }
  catch(ex) 
  {
    console.log("error in saving the template" +ex.message);
    throw ex.message;
  }
}

export async function GetTemplate(id) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/ManageTemplate/${id}`);
    return response.data;
  }
  catch(ex) 
  {
    console.log("error in saving the template" +ex.message);
    throw ex.message;
  }
}

export async function GetSfdtTemplate(id, clientId) {
  try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/ManageTemplate/Finalize/${id}/${clientId}`);
    return response.data;
  }
  catch (ex) {
    console.log("error in saving the template" + ex.message);
    throw ex.message;
  }
}

export async function GetFinalizeTemplate(id) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/Narrative/Finalize/${id}`);
    return response.data;
  }
  catch(ex) 
  {
    console.log("error in saving the template" +ex.message);
    throw ex.message;
  }
}

export async function GetTemplateHtml(clientId,id) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/ManageTemplate/Html/${clientId}/${id}`);
    return response.data;
  }
  catch(ex) 
  {
    console.log("error in saving the template" +ex.message);
    throw ex.message;
  }
}

export async function ReProcess({id,data}) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(baseUrl + `api/Narrative/Reprocess/` + id,data);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function PollProgress(id) 
{
  try {
    let key = localStorage.getItem('authToken'); 
    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.get(baseUrl + `api/Narrative/PollProgress/` + id);
    return response.data;
  }
  catch(ex) 
  {
    throw ex.message;
  }
}

export async function ExportToWord(blob, filename) {
  try {
    let key = localStorage.getItem('authToken');

    const formData = new FormData();
    formData.append('file', blob, `${filename}`);

    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(
      baseUrl + `api/Narrative/ExportToWord`,
      formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType:'blob'
    }
    );
    return { blob: response.data, filenamewithEx: filename };
  }
  catch (ex) {
    throw ex.message;
  }
}

export async function ExportToPdf(blob, filename) {
  try {
    let key = localStorage.getItem('authToken');

    const formData = new FormData();
    formData.append('file', blob, `${filename}`);

    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(
      baseUrl + `api/Narrative/ExportToPdf`,
      formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType:'blob'
    }
    );
    return { blob: response.data, filenamewithEx: filename };
  }
  catch (ex) {
    throw ex.message;
  }
}

export async function SaveTemplate(tempId, templateName, evalId , jsonBlob) {

  try {
    const formData = new FormData();
    formData.append('file', jsonBlob, `${templateName}.json`);
    formData.append('EvaluationTypeId', evalId);
    formData.append('Pkid', tempId);
    formData.append('Name', templateName);

    axios.defaults.headers.common['Authorization'] = localStorage.getItem('authToken');
    const response = await axios.post(
      baseUrl + `api/ManageTemplate`,
    formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

    return response.data;
  }
  catch(ex) {
    console.log("error in saving the template" +ex.message);
    throw ex.message;
  }
}

export async function FinalizeTemplate(blob, clientId, finalizeId) {
  try {
    let key = localStorage.getItem('authToken');

    const formData = new FormData();
    formData.append('file', blob, `${clientId}`);
    formData.append('patientId', clientId);
    formData.append('pkid', finalizeId);

    axios.defaults.headers.common['Authorization'] = key;
    const response = await axios.post(
      baseUrl + `api/Narrative/Finalize`,
      formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    );
    return response.data;
  }
  catch (ex) {
    throw ex.message;
  }
}