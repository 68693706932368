import React from "react";
import { CreateTemplate } from "../components/templates/CreateTemplate";

export function Template() {
  return (
    <>
      <CreateTemplate />
    </>
  );
}
