import { create } from "zustand";
import { GetPracticeInfo } from "../apis/Practice";

const useReportClientStore = create((set) => ({
  isLoading: false,
  data: {},
  reset: () => set({ data: {} }),
  update: (_new) => set({ data: _new }),
  setIsLoading: (_) => set({ isLoading: _ }),
  fetchClientInfo: async (cid) => {
    set({ isLoading: true });
    const data = await loadClientInfo(cid);
    set({ data: data, isLoading: false });
  },
}));

const loadClientInfo = async (cid) => {
  const { data: response, msg } = await GetPracticeInfo(cid.split('&key=')[0]);

  if (!response || !response.data) {    
    return null;
  }

  return response.data;
};

export default useReportClientStore;
