import React, { useState, useEffect, useRef } from "react";
import parse, { domToReact } from "html-react-parser";
import { useParams, useNavigate } from "react-router-dom";
import { Complete, DownloadProccessedTable, GetNarratives, GetTemplateHtml, GetTemplates } from "../../apis/Narratives";
import { PlaceHolder } from "./PlaceHolders";
import { FabComponent } from '@syncfusion/ej2-react-buttons';
import { GetPracticeInfo } from "../../apis/Practice";
import SpinnerPage from '../SpinnerPage';
import { GetScoreReports } from "../../apis";
import useReportTableStore from "../../store/report_table.store";
import { ConvDotPlotData } from "../../utils/convert";
import ChartSectionType from "../home/ChartSectionType";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


function EditNarratives() {

  let portal = process.env.REACT_APP_BASEURL;
  let env = process.env.REACT_APP_ENV;

  const clientId = useParams("id").id;
  const evalId = useParams("evalId").evalId;
  const navigate = useNavigate();

  const updateReportTables = useReportTableStore((state) => state.update);

  const [narrativeData, setNarrativeData] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const [error, setError] = useState(null);
  const [selectValue, setselectValue] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [showFABMenu, setShowFABMenu] = useState(false);
  const [templateHtml, setTemplateHtml] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [showUnUsed, setShowUnUsed] = useState(false);
  const [toc, setToc] = useState([]);
  const [tables, setTables] = useState([]);
  const [showDownloadTableModal, setShowDownloadTableModal] = useState(false);

  let selectTableDownloadValue;
  let unusedNarrative = [];

  useEffect(() => {
    const fetchData = async () => {
      var selected;
      var narData;
      try {
        await Promise.all([

          GetNarratives(clientId)
            .then((data) => {
              narData = data;
              setNarrativeData(data);
            })
            .catch((err) => {
              console.log(err);
              setError(err);
            }),

          GetPracticeInfo(clientId)
            .then((response) => {
              let data = response.data.data;
              setClientInfo(data);
            }),

          GetTemplates(clientId, evalId)
            .then((data) => {
              setTemplateOptions(data);
              selected = data.find(option => option.isSelected);
            },

          GetScoreReports(`${clientId}`).then(({ data, msg }) => {
            const tbl = ConvDotPlotData(data.data);
            setTables(tbl);
            updateReportTables(tbl);
          })
          .catch((err) => {
            console.log(err);
          })
        )]).finally(() => {
          setIsLoading(false);
        })

        if (!!selected) {
          setselectValue(selected.pkid);
          getHtmlTemplate(selected.pkid, narData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    window.addEventListener('beforeunload', clearStorage);
  }, []);

  const clearStorage = () => {
    localStorage.removeItem(`unUsedNarrative${clientId}`);
  };

  const onLock = (id) => {
    var tt = toc.map(t => { if (t.id == id) t.lock = !t.lock; return t });
    setToc(tt);
  }

  const options = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }
      const narr = narrativeData.find((ele) => ele.narrativeTypeCode === attribs.id);

      if (!!narr) {
        return (
          <div>
            <PlaceHolder
              data={narr}
              clientId={clientId}
              hasEdit={true}
              onLock={onLock} />
            {domToReact(children, options)}
          </div>
        );
      }

      const chartTbl = tables.find((ele) => ele.reportType === attribs.id);
      if (!!chartTbl) {
        return (
          <ChartSectionType type={chartTbl.reportType}></ChartSectionType>
        );
      }
    },
  };

  const replacePlaceHolders = (data) => {
    if (!narrativeData) return;

    narrativeData.forEach(ele => {
      const regex = new RegExp(`(&lt;&lt;)${ele.narrativeTypeCode}(&gt;&gt;)`, 'g');
      if (regex.test(data)) {
        var section = `<section id="${ele.narrativeTypeCode}"></section>`;
        if (ele.inline === true) section = `<span style='display:inline'>${ele.plainText}</span>`;
        data = data.replace(regex, `${section}`);
      } else if (ele.pkid != '"00000000-0000-0000-0000-000000000000"') {
        unusedNarrative.push(ele);
      }
    });

    if (unusedNarrative.length > 0 && !showUnUsed) setShowUnUsed(true);

    //Replace Client Info
    const regexName = /(&lt;&lt;)FIRSTNAME(&gt;&gt;)/gm;
    data = data.replace(regexName, clientInfo.firstName);
    const regexFormal = /(&lt;&lt;)FORMALFIRST(&gt;&gt;)/gm;
    data = data.replace(regexFormal, clientInfo.formalFirst);
    const regexLast = /(&lt;&lt;)LASTNAME(&gt;&gt;)/gm;
    data = data.replace(regexLast, clientInfo.lastName);
    const regexdob = /(&lt;&lt;)DOB(&gt;&gt;)/gm;
    data = data.replace(regexdob, clientInfo.dob);
    const regexpro = /(&lt;&lt;)PRONOUN(&gt;&gt;)/gm;
    data = data.replace(regexpro, clientInfo.pronoun);


    //Replace chart 
    tables.forEach(ele => {
      const regex = new RegExp(`(&lt;&lt;)CHART:${ele.reportType}(&gt;&gt;)`, 'g');
      data = data.replace(regex, `<section id="${ele.reportType}"></section>`);
    });

    const regex = new RegExp(`(&lt;&lt;)CHART:.*(&gt;&gt;)`, 'g');
    data = data.replace(regex, '');

    return parse(data, options);
  }

  const getToc = (html, narData = null) => {
    let topic = [];
    (narData ?? narrativeData).forEach(ele => {
      let index = html.search(`&lt;&lt;${ele.narrativeTypeCode}&gt;&gt;`);
      if (index != -1) {
        topic.push({
          narrativeTypeCode: ele.narrativeTypeCode,
          narrativeTypeName: ele.narrativeTypeName,
          lock: ele.lock,
          id: ele.pkid,
          index: index
        })
      }
    });
    topic = topic.sort(function (obj1, obj2) {
      return obj1.index - obj2.index;
    });

    return topic;
  }

  const handleTemplateChange = (value) => {
    getHtmlTemplate(value.target.value);
  };

  const getHtmlTemplate = (value, narData = null) => {
    setIsLoading(true);
    GetTemplateHtml(clientId, value).then((data) => {
      setTemplateHtml(data.html);
      setselectValue(value);
      setToc(getToc(data.html, narData));
      setIsLoading(false);
    });
  }

  const onComplete = () => {
    setIsLoading(true);
    Complete(clientId).then((data) => {
      setIsLoading(false);
    });
  }

  const scrollToElementById = (id) => {
    const element = document.getElementById(id);
    console.log(element);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'auto' });
      }, 300);
      setTimeout(() => {
        document.getElementsByClassName('scroll-editor-wrapper')[0].scrollBy({
          top: -100,
          behavior: 'auto'
        });
      }, 300);
    }
  };

  const handleToggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleunUsedNavigate = () => {
    localStorage.setItem(`unUsedNarrative${clientId}`, JSON.stringify({ data: unusedNarrative, clientInfo }));
    window.open(`/unUsedNarrative/${clientId}/${evalId}`, '_blank');
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),
  };

  const DownloadTableDialog = () => {
    return (
      <div className="dialogContent">
        <h2 className="dialogText">
          Select Table Template
        </h2>
        <div className="flex flex-col gap-6 items-center select-ul">
          <select
            style={customStyles}
            value={selectTableDownloadValue}
            PlaceHolder="Select Template"
            onChange={(value) => selectTableDownloadValue = value.target.value}>
            <option value="">Select Template</option>
            <option value="Tables Simple">Tables Simple</option>
            <option value="Tables With Descriptions">Tables With Descriptions</option>
          </select>
        </div>
      </div>
    );
  };

  let downloadTableButtons = [
    {
      click: () => {
        setShowDownloadTableModal(false);

        if(!selectTableDownloadValue){
          withReactContent(Swal).fire({
            title: "Download Table",
            html: "Please select Table type first.",
            confirmButtonText: "Ok",
            icon: "warning",
            showCancelButton: false,
         })
         return;
        }
        const downloadLink = document.createElement('a');
        downloadLink.href = `${process.env.REACT_APP_BASEURL}api/ManageTemplate/ProcessedTable/${clientId}/${selectTableDownloadValue}`;
        downloadLink.target = '_blank';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      buttonModel: {
        isPrimary: true,
        content: 'Download',
      },
    }, {
      click: () => {
        setShowDownloadTableModal(false);
      },
      buttonModel: {
        isPrimary: false,
        content: 'Cancel',
      },
    },
  ];

  return (
    <div className="home flex-container" style={{fontFamily: 'sans-serif'}}>
      {isLoading && (<SpinnerPage></SpinnerPage>)}
      <div className="flex-left">
        <div class="position-sticky">
          {env == "DEV" && (
            <div class="mt-2">
              <span class="env-block"> DEV</span>
            </div>)}
          <div class="list-group list-group-flush mx-3 mt-4">
            <img src="/assets/images/reverb/ReverbLogoAndText.svg" class="mb-3 ml-4" style={{ width: '70%' }} />

            <a href={portal} title="Home" class="dropdown-item side-item ml-4 side-item-active">
              <img src="/assets/images/icons/Dashboard 3.svg" class="fas side-panel-icon mr-3" /> Home
            </a>
            {clientInfo?.isPracticeAdmin == true && (
              <a class="dropdown-item side-item ml-4 " href={`${portal}ManagePractice`}>
                <i class="fas fa-users-cog side-panel-icon mr-3"></i>
                Manage Practice
              </a>)}
            <a class="dropdown-item side-item ml-4 " href={`${portal}ReportType`}>
              <img src="/assets/images/icons/File Report.svg" class="fas side-panel-icon mr-3" />
              Supported Reports</a>
            <a class="dropdown-item side-item ml-4" href="mailto:support@testrev.com">
              <img src="/assets/images/icons/Customer Support 1.svg" class="fas side-panel-icon mr-3" />
              Support</a>
            <a class="dropdown-item side-item ml-4" href={`${portal}Account/SignOut`}>
              <img src="/assets/images/icons/Logout 1.svg" class="fas side-panel-icon mr-3" />
              Log out</a>
          </div>
        </div>
      </div>
      <div className="flex-right">
        <div className={`collapsible-content ${isOpen ? 'open' : ''}`}>
          <div style={{ marginTop: '2px', background: '#eaa70082', padding: '10px', borderRadius: '5px', display: 'flex' }}>
            <div>
              Click <a style={{ color: "#0a58ca" }} href={`${portal}Narrative/${clientId}`}>here</a> to access the Legacy Edit Narrative
            </div>
            <div style={{ textAlign: 'right', flex: 'auto' }}>
              <button style={{
                borderRadius: '5px',
                borderWidth: '1px',
                borderColor: '#EAA700',
                margin: '5px'
              }} onClick={handleToggle}>
                <i class={isOpen ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'}></i>
              </button>
            </div>
          </div>

          <div className="edit-narrative-header">
            <div class="grid grid-cols-2 grid-flow-row gap-5">
              <div className="client-detail-card">
                <table>
                  <tr>
                    <td className="text-right">First Name:</td>
                    <td className="pl-[10px]">{clientInfo?.firstName}</td>
                  </tr>
                  <tr>
                    <td className="text-right">Last Name:</td>
                    <td className="pl-[10px]">{clientInfo?.lastName}</td>
                  </tr>
                  <tr>
                    <td className="text-right">DOB:</td>
                    <td className="pl-[10px]">{clientInfo?.dob}</td>
                  </tr>
                  <tr>
                    <td className="text-right">Email:</td>
                    <td className="pl-[10px]">{clientInfo?.email}</td>
                  </tr>
                </table>
                <div className="button-container">
                  <div className="text-center w-full">
                    <button className="btn btn-primary btn-quickactions narative-button mb-[5px] mt-[5px]" onClick={() => setShowDownloadTableModal(true)}>Download Tables</button>

                  </div>

                  <div className="text-center w-full">
                    <button className="btn btn-primary btn-quickactions narative-button mb-[5px] mt-[5px]" onClick={() => { window.location.href = `${portal}Batch/${clientId}` }}>Client detail</button>

                  </div>
                </div>

                {showDownloadTableModal && (<DialogComponent
                  isModal={true}
                  buttons={downloadTableButtons}
                  visible={showDownloadTableModal}
                  width="435px"
                  minHeight="220px"
                  content={DownloadTableDialog} >
                </DialogComponent>)}
              </div>
              <div className="client-detail-card">
                <div className="quick-action-title">
                  <span>Quick Actions</span>
                </div>
                <div className="button-container">
                  <div className="text-center w-full">
                    <button className="btn btn-primary btn-quickactions narative-button mb-[5px] mt-[5px]" onClick={() => navigate("/finalize",
                      {
                        state: {
                          clientId: clientId,
                          template: selectValue,
                          evalId: evalId
                        }
                      })}>Generate Draft</button>
                  </div>
                  <div className="text-center w-full">
                    {clientInfo?.finalizeId != '00000000-0000-0000-0000-000000000000' && (
                      <button className="btn btn-primary btn-quickactions narative-button mb-[5px] mt-[5px]" onClick={() => navigate("/finalize",
                        {
                          state: {
                            clientId: clientId,
                            template: selectValue,
                            evalId: evalId,
                            finalizeId: clientInfo?.finalizeId,
                          }
                        })}>Edit Existing Draft</button>)}
                  </div>
                  <div className="text-center w-full">
                    {
                      showUnUsed && (
                        <button className="btn btn-primary btn-quickactions narative-button mb-[5px] mt-[5px]" onClick={handleunUsedNavigate}>Additional Narratives</button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>


            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/2">
                <div className="flex flex-col gap-6 items-center select-ul">
                  <select
                    style={customStyles}
                    PlaceHolder="Select Template"
                    value={selectValue}
                    onChange={handleTemplateChange}>
                    <option value="">Select Template</option>
                    {templateOptions?.map(i => (<option key={i.pkid} value={i.pkid}>{i.name}</option>))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {templateHtml && (<div className="edit-narrative-content grid grid-cols-5 gap-4">
          <div className="col-span-1" style={{ cursor: 'pointer' }}>
            <div className="custom-scroll" style={{ width: '200px', height: 'calc(100vh - 60px)' }}>
              {toc?.map(nar =>
                <div style={{ display: 'flex' }}> {nar.lock ? (<i style={{ alignContent: "center", flex: '0 0 14px', fontSize: '0.8em' }} className="fas fa-lock"></i>) : (<div style={{ flex: '0 0 14px' }}></div>)}
                  <div className="toc" onClick={() => scrollToElementById(nar.narrativeTypeCode)}>
                    {nar.narrativeTypeName}
                  </div></div>)}
            </div>
          </div>
          <div className="scroll-editor-wrapper custom-scroll col-span-4">
            <div className="editor-wrapper">
              {replacePlaceHolders(templateHtml)}
            </div>

          </div>
        </div>)}
      </div>

      {showFABMenu && (<div className="fab-menu">

        {clientInfo?.entitlements?.some(ele => ele == "Visualization") && (
          <div className="text-right mb-1">
            <button className="btn-flow e-btn btn-primary" onClick={() => navigate(`/report/${clientId}`)}><i class="fa-solid fa-chart-simple mr-2"></i>Visual Report</button>
          </div>)}
        <div className="text-right mb-1">
          <button className="btn-flow e-btn btn-primary" onClick={() => navigate("/finalize",
            {
              state: {
                clientId: clientId,
                template: selectValue,
                evalId: evalId
              }
            })}><i class="fas fa-file-invoice mr-2"></i>Generate Draft</button>
        </div>
        <div className="text-right mb-1">
          <button className="btn-flow e-btn btn-primary" onClick={() => { window.location.href = `${portal}Batch/${clientId}` }}>Client detail</button>
        </div>
        <div className="text-right mb-1">
          {clientInfo?.finalizeId != '00000000-0000-0000-0000-000000000000' && (
            <button className="btn-flow e-btn btn-primary" onClick={() => navigate("/finalize",
              {
                state: {
                  clientId: clientId,
                  template: selectValue,
                  evalId: evalId,
                  finalizeId: clientInfo?.finalizeId,
                }
              })}>Edit Existing Draft</button>)}
        </div>
        <div className="text-right mb-1">
          {
            showUnUsed && (
              <button className="btn-flow e-btn btn-primary" onClick={handleunUsedNavigate}>Additional Narratives</button>
            )
          }
        </div>
        <div className="text-right mb-1">
          <button className="btn-flow e-btn btn-primary" onClick={onComplete}><i class="fas fa-check-square mr-2"></i>Complete and Save as Reference</button>
        </div>
      </div>)}
      {selectValue && (
        <FabComponent id='fab' cssClass='btn-primary' target='#targetElement' onClick={() => { setShowFABMenu(!showFABMenu) }} content="<img src='/assets/images/icons/Narrative.svg' class='mr-2' style='width: 1.2rem;'/></div><span style='font-width: bold !important;'>Narrative</span>"></FabComponent>
      )}
    </div>
  );
}

export default EditNarratives;
