import useReportTableStore from "../../store/report_table.store";
import DotPlot from "../DotPlot";
import RenderBarChart from "../RenderBarChart";

const ChartSectionType = ({type, image = false}) => {
  const reportTables = useReportTableStore((state) => state.tables);

  if (!reportTables || !reportTables.length) return null;

  let tableType = reportTables.find(item => item.reportType === type);
 
  return (
    <div style={{width:'800px',marginLeft:'-1in',background:'white'}}>
      {tableType?.type?.toLowerCase() == "redot" ? (
         <section className="mb-[30px]" key={"dtd-" + tableType.reportType}>
         <div className="container">
           <div className="p-4 border border-gray rounded-[20px]">
              <div className="mb-[30px]">
                <p className="eyebrow">{tableType.title}</p>
                {tableType.description ? (
                  <div className="graph-scale-title col-span-3 mb-[10px]">
                    <p>{tableType.description}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
 
             <div className="dot-plot-sections" id="graph-example">
               <DotPlot data={tableType} image={image} />
             </div>
           </div>
         </div>
       </section>
      ) : tableType?.type?.toLowerCase() == "rebar" ? (
        <section className="mb-[30px]" key={"dtdac-" + tableType.reportType}>
        <div id={tableType} className="container">
          <div className="p-4 border border-gray rounded-[20px]">
                <div className="mb-[30px]">
                  <p className="eyebrow">{tableType.title}</p>
                  {tableType.description ? (
                    <div className="graph-scale-title col-span-3 mb-[10px]">
                      <p>{tableType.description}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

            <div className="bar-chart-sections" id="bar-example">
              {[tableType].map((graph, i) => {
                return <RenderBarChart key={"bgd-" + i} data={graph} image={image} />;
              })}
            </div>
          </div>
        </div>
      </section>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ChartSectionType;
