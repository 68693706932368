import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { GetTemplate, SaveTemplate } from "../../apis/Narratives";
import { toastError, toastSuccess } from "../../utils/toast";
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import "./CreateTemplate.css";
import { useParams } from "react-router-dom";
import SpinnerPage from "../SpinnerPage";

DocumentEditorContainerComponent.Inject(Toolbar);

export function CreateTemplate() {
  const hostUrl = process.env.REACT_APP_BASEURL;

  const evalId = useParams("id").id;
  const tempId = useParams("tempId").tempId;

  const [error, setError] = useState(null);
  const [templateName, settemplateName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const refInput = useRef(null)
  const editor = useRef(null);
  let titleBar;

  useEffect(() => {
    rendereComplete();
    if (tempId) {
      setIsLoading(true);
      GetTemplate(tempId)
        .then((data) => {
          settemplateName(data.name);
          editor.current.documentEditor.open(data.html);
          setIsLoading(false);
        })
    }
  }, []);


  const uploadAsyncSettings = {
    saveUrl: hostUrl + "api/ImportFromWord",
  };


  function handleTemplateNameChange(event) {
    settemplateName(event.target.value);
    if (error) setError(null);
  }

  async function handleSaveTemplate() {

    if (!refInput?.current.value) {
      setError("*Template name is required");
      return;
    }

    try {
      editor.current.documentEditor
        .saveAsBlob("Sfdt")
        .then(async (blob) => {
          var response = await SaveTemplate(tempId, refInput?.current.value, evalId, blob);
          settemplateName(null);
          toastSuccess("Template created successfully!");
        });


    } catch (ex) {
      console.log("Error in saving template", ex);
      toastError("Error in saving template. plese try again later");
    }
  }

  function addHeaders(args: any) {
    args.currentRequest.setRequestHeader('Authorization', localStorage.getItem('authToken'));
  }

  let toolbarOptions = [
    {
      tooltipText: 'Save',
      template: '<button title="Save" class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-caret-hide" type="button" id="savebtn"><span class="e-btn-icon e-icons e-de-save-icon e-icon-left"></span><span class="e-tbar-btn-text">' +
        'Save' +
        '</span>',
      id: 'savebtn',
      text: 'Save',
    },
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'Separator',
    'Find',
    'Separator'
  ];

  const rendereComplete = () => {
    window.onbeforeunload = function () {
      return "Want to save your changes?";
    };
    editor.current.documentEditor.pageOutline = "#E0E0E0";
    editor.current.documentEditor.acceptTab = true;
    editor.current.documentEditorSettings.showRuler = true;
    editor.current.documentEditor.resize();

    editor.current.documentChange = () => {
      //titleBar.updateDocumentTitle();
      editor.current.documentEditor.focusIn();
    };
  };

  const onToolbarClick = (args) => {
    if (args.item.id === 'savebtn') {
      handleSaveTemplate();
    }
  };

  return (
    <div className="home home-root">
      {isLoading && (<SpinnerPage></SpinnerPage>)}
      <section>
        <div class="md:flex md:items-center">
          <div class="md:w-1/3 m-3">
            <input
              ref={refInput}
              class="input-transparent appearance-none rounded w-full py-2 px-4 text-gray-300 leading-tight"
              id="template name"
              placeholder="Template Name"
              type="text"
              value={templateName}
              onChange={handleTemplateNameChange}
            />
          </div>
          {error ? <p className="text-red"> {error}</p> : null}
        </div>
        <div className="full-rte-mode" id="rteTools">
          <div className="rte-control-section">
            <div id='documenteditor_titlebar' className="e-de-ctn-title"></div>
            <DocumentEditorContainerComponent id="container"
              ref={editor}
              style={{ display: "block" }}
              height={"calc(100vh - 65px)"}
              serviceUrl={hostUrl}
              enableToolbar={true}
              toolbarItems={toolbarOptions}
              toolbarClick={onToolbarClick}
              locale="en-US" />
          </div>
        </div>
      </section>
    </div>
  );
}
